.dashbord_main_wrapper {
  color: white;
  background-color: #191919;
  padding: 1.5rem;
}

.dashbord_name_title {
  font-weight: 700;
  font-size: 1.75rem;
  text-align: left;
  margin: 0px;
}

.dashbord_main_inner {
  margin-top: 30px;
}

.current_statistics_graph{
  max-width: 320px;
  max-height: 370px;
  align-items: center;
}

.dashbord_title {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-align: left;
  line-height: 1.38;
  margin: 0px;
}

.available_balance_wrap {
  display: flex;
}

.available_balance_wrap ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  margin: 0 -12.5px;
  flex-wrap: wrap;
}

.available_balance_box_image {
  width: 40px;
  height: 40px;
  background-color: #26281d;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border-radius: 2px;
  position: relative;
}

.available_balance_box {
  padding: 23px 20px;
  background-color: #1d1d1d;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
}

.available_balance_details h5 {
  font-size: 14px;
  color: #bfc0c0;
  line-height: 1;
  margin-bottom: 5px;
}

.available_balance_details p {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin: 0px;
}

.available_balance_wrap ul li {
  width: 50%;
  padding: 0 12.5px;
}

.available_balance_wrap {
  margin-top: 10px;
}

.available_balance_wrap ul li:nth-child(n + 3) {
  margin-top: 15px;
}

.used_balance_chart canvas {
  width: 80px !important;
  height: 80px !important;
}

.used_balance_chart {
  margin-left: auto;
}

.order_type_wrap .btn,
.order_type_wrap .btn-check:active + .btn-success,
.order_type_wrap .btn-check:checked + .btn-success,
.order_type_wrap .btn-success.active,
.order_type_wrap .btn-success:active,
.order_type_wrap .show > .btn-success.dropdown-toggle {
  background-color: unset;
  padding: unset;
  border-color: unset;
  outline: unset !important;
  box-shadow: unset;
  border: unset !important;
}

.order_type_wrap .btn-success:not(:disabled):not(.disabled).active,
.order_type_wrap .btn-success:not(:disabled):not(.disabled):active,
.order_type_wrap .show > .btn-success.dropdown-toggle {
  color: unset;
  background-color: unset;
  border-color: unset;
}

.order_type_wrap .btn-success:not(:disabled):not(.disabled).active:focus,
.order_type_wrap .btn-success:not(:disabled):not(.disabled):active:focus,
.order_type_wrap .show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset;
}

.order_type_wrap .dropdown-toggle::after {
  content: unset;
}

.order_type_wrap {
  position: absolute;
  right: 15px;
  top: 20px;
}

.order_type_wrap .btn img {
  filter: invert(81%) sepia(52%) saturate(632%) hue-rotate(21deg)
    brightness(105%) contrast(110%);
}

.order_type_wrap .dropdown-menu {
  background-color: #0f0e0e;
  color: #fff;
}

.order_type_wrap .dropdown-item {
  color: #fff;
}

.order_type_wrap .dropdown-item:focus,
.order_type_wrap .dropdown-item:hover {
  background-color: unset;
}

.order_type_wrap .dropdown-menu {
  min-width: unset;
}

.available_balance_wrap.pnl_wrapper {
  margin-top: 15px;
}

.pnl_wrapper .available_balance_details + .available_balance_details {
  margin-left: 50px;
}

.active_quants_wrappper {
  margin: 30px 0;
}

.active_quants_wrappper .active_card_info {
  width: calc(50% - 12.5px);
  margin-right: 0px;
}

.activeQ_inner {
  justify-content: space-between;
}

.active_card_info {
  font-family: 'Manrope';
}

.ac_quants_wrap {
  min-height: 70px;
}

.active_card_info > img {
  height: 80px;
}

.active_card_info .ac_quants_name {
  margin-top: 5px;
}

.common_quants_title_wrap h3 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
  line-height: 1;
}

.common_quants_title_wrap {
  display: flex;
  justify-content: space-between;
  line-height: 1;
}

.all_links_text {
  padding: 0px;
  font-size: 16px;
  font-weight: 700;
  color: #cefd1e;
}

.all_links_text:hover {
  color: rgba(206, 253, 30, 0.5);
  text-decoration: none;
}

.active_card_info:nth-child(1),
.active_card_info:nth-child(2) {
  margin-top: 10px;
}

.common_quants_title_wrap .order_type_wrap {
  position: unset;
}

.common_quants_title_wrap .order_type_wrap .btn {
  display: flex;
  align-items: center;
}

.common_quants_title_wrap .order_type_wrap .btn p {
  margin-right: 10px;
  line-height: 1;
  margin-bottom: 0px;
}

.common_quants_title_wrap .order_type_wrap .btn span {
  line-height: 1;
  transform: rotate(0deg);
}

.common_quants_title_wrap .order_type_wrap .show.dropdown .btn span {
  transform: rotate(180deg);
}

.order_tabpe_wrapper {
  margin-top: 10px;
}

.order_tabpe_wrapper > div.MuiPaper-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.order_tabpe_wrapper > div.MuiPaper-root::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: #f5f5f5;
}

.order_tabpe_wrapper > div.MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #434446;
  border: 1px solid #434446;
}

.market_overview_with_quants {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #1d1d1d;
  border-radius: 14px;
  margin-top: 10px;
}

.quants_icon_wrap {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #343434;
  padding: 7px;
}

.quants_icon_wrap.active {
  background-color: #0f0e0e;
}

.quants_icon_wrap img {
  width: 100%;
}

.quants_list .order_type_wrap {
  position: unset;
  margin-left: 15px;
}

.quants_list ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.quants_list ul li {
  margin-right: 15px;
  margin-bottom: 7px;
}

.market_titls {
  text-align: left;
}

.market_titls p {
  margin-bottom: 0px;
}

.action_points_list ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.action_points_list ul li {
  flex-grow: 1;
}

.action_points_list ul li .active_point_info_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active_point_img {
  margin: 17px 0 10px;
}

.message_info_wrap {
  width: 100%;
  height: 340px;
  background-color: #1d1d1d;
  border-radius: 6px;
  margin-top: 10px;
}

.fet_offer_info {
  background: linear-gradient(254.54deg, #3e51a3 -11.09%, #00d37f 148.9%);
  border-radius: 6px;
  padding: 10px 20px;
}

.fet_offer_wrap {
  text-align: left;
  margin-top: 8px;
}

.fet_offer_details p,
.invest_wrapper p {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  max-width: 270px;
  text-align: left;
  margin-bottom: 15px;
}

.fet_offer_details p span,
.invest_wrapper p span {
  font-size: 22px;
  color: #cefd1e;
  font-weight: 700;
}

.fet_offer_info {
  display: flex;
  justify-content: space-between;
}

.cm_next_btn {
  padding: 10px 25px;
  background-color: #cefd1e;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  color: #191919;
  font-weight: 600;
}

.what_next_wrapper {
  margin: 30px 0;
}

.fet_offer_wrap > div + div {
  margin-top: 10px;
}

.help_wrapper {
  background: #15a98b;
  border-radius: 6px;
  padding: 24px 20px;
}

.help_wrapper h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
}

.help_wrapper p {
  max-width: 245px;
  font-size: 14px;
  margin: 7px 0 20px;
}

.cm_next_btn:hover {
  background-color: #fff;
}

.invest_wrapper {
  padding: 16px 20px;
  background: linear-gradient(122.75deg, #191f20 -43.98%, #204348 100%);
  border-radius: 6px;
}

.invest_wrapper p span {
  color: #fff;
}

@media screen and (max-width: 1600px) {
  .available_balance_box {
    padding: 15px 10px;
  }

  .available_balance_details p {
    font-size: 20px;
  }
}

@media screen and (max-width: 1530px) {
  .available_balance_wrap ul li {
    width: 100%;
  }

  .available_balance_wrap ul li:nth-child(n + 2) {
    margin-top: 15px;
  }

  .available_balance_info > .row > div {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media screen and (max-width: 1199px) {
  .available_balance_details p {
    font-size: 18px;
  }
  .market_titls h4 {
    font-size: 22px;
  }
  .market_titls p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1199px) {
  .market_overview_with_quants {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .quants_list ul {
    padding-left: 0px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .quants_list {
    position: unset;
  }
  .quants_list .order_type_wrap {
    position: absolute;
  }
}
