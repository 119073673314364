.sidebarItem {
  display: inline-block;
  padding: 13px 33px;
  text-align: left;
  width: 100%;
  margin-bottom: 13px;
  transition: all 0.2s;
  color: white;
  background: transparent;
}



.sidebarItem:hover{
  cursor: pointer;
  color: #00d37f;
  background: linear-gradient(
    90deg,
    rgba(0, 211, 127, 0.1) 0%,
    rgba(0, 211, 127, 0) 100%
  );
}

.sidebarItem:hover .flex img {
  filter: invert(50%) sepia(30%) saturate(4500%) hue-rotate(111deg)
    brightness(96%) contrast(101%);
}
.sidebarItem .flex {
  display: flex;
  align-items: center;
  gap: 1.1rem;
}

.sidebarItem .flex img {
  width: 22px;
  filter: brightness(0) invert(1);
}
.sidebarItem .flex h3 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0rem;
  font-family: 'Manrope', sans-serif;
  line-height: 1.5;
}
