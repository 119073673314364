.subHeading {
  color: #00d37f;
  position: relative;
}

.subHeadingTestimonial {
  color: #00d37f;
  position: relative;
}

.subHeading::after {
  content: '';
  position: absolute;
  right: 0;
  width: 191px;
  bottom: -17px;
  height: 17px;
  background-image: url('../../assets/home/headingLine.svg');
  background-repeat: no-repeat;
}
.bgTitleLine {
  position: relative;
}
.bgTitleLine::after {
  content: '';
  position: absolute;
  right: 0;
  width: 125px;
  bottom: -17px;
  height: 17px;
  background-image: url('../../assets/home/headingLine.svg');
  background-repeat: no-repeat;
}

/* .wealth_container_bg {
  height: 300px;
  margin-bottom: -160px;
  border-radius: 100% 100% 0% 0%;
  margin-top: 175px;
  background: #082427;
  position: relative;
}

.wealth_container_bg::after {
  height: 230px;
  content: '';
  width: 268px;
  position: absolute;
  left: 0;
  background-image: url('../../assets/home/buildingWealthBg.svg');
  transform: rotate(-14.99deg);
  top: -158px;
  border-radius: 0% 0% 12% 2%;
} */

.wealth_container {
  margin-top: 275px;
  position: relative;
  background-image: url('../../assets/home/buildWealthBg.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.wealth_vector {
  background-image: url('../../assets/home/buildingWealthBg.svg');
  width: 268px;
  height: 230px;
  top: 135px;
  position: absolute;
}

/* .wealth_container::after{
  height: 230px;
  content: '';
  width: 268px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../assets/home/buildingWealthBg.svg');
} */

.wealth_container .wealth_inner_container {
  padding-top: 150px;
  padding-bottom: 61.5px;
  text-align: left;
  /* border-radius: 50% 50% 0% 0%; */
  /* height: 272px; */
}

.wealth_container .wealth_inner_container h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.wealth_container .wealth_inner_container p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 160%;
  color: #ffffff;
  padding-top: 43px;
}

.wealth_container .wealth_inner_container .imgWrapper {
  padding-top: 36px;
}

.wealth_container .wealth_inner_container .price {
  color: white;
  padding-top: 14px;
}

/* .wealth_container .wealth_inner_container .types{
    color: white;

} */

.wealth_container .processTab {
  height: 5px;
  background-color: #123c41;
  color: #00d37f;
}

.wealth_container .processPrice {
  font-size: 24px;
  color: #cefd1e;
}

.wealth_container .progress-bar {
  background-color: #123445 !important;
}

.wealth_container .tabTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}

.wealth_container .processSpace {
  padding-top: 57px;
}

.wealth_container .tabContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  text-align: right;
  color: #ffffff;
  /* padding-top: 34px; */
}

.wealth_container .wealthBrnWrap {
  margin-top: 28px;
}

.wealth_container .btnImg {
  width: 169px;
  height: 55px;
}

.wealth_container .homeBtn {
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0 20px;
}

.wealth_container .monthlyInvestWrapper {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  text-align: right;
  color: #ffffff;
}

.wealth_container .monthlyValueWrapper {
  background: rgba(18, 60, 65, 0.15);
}

.wealth_container .monthlyCurrencyWrapper {
  padding: 0 0 0 5px;
}

.wealth_container .montlyCurreny {
  margin-right: 2px;
}

.wealth_container .monthlyInput {
  border: none;
  height: 38px;
  width: 150px;
  color: inherit;
  background: transparent;
  text-align: right;
  flex-grow: 1;
  outline: 0px;
  opacity: 1;
  padding: 0px;
}

.wealth_container .RateInput {
  border: none;
  height: 38px;
  width: 150px;
  color: inherit;
  background: transparent;
  text-align: right;
  flex-grow: 1;
  outline: 0px;
  opacity: 1;
  padding: 0px;
  margin-right: 5px;
}

.wealth_container input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.wealth_container .timePeriodWrapper {
  border: 1px solid #123C41!;
  border-radius: 67px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  margin-top: 34px;
}

.wealth_container .timePeriodWrapper .timePeriod {
  display: inline-block;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 15px;
  text-align: center;
  width: 33%;
}

.wealth_container .timePeriodWrapper .timePeriod.active {
  background: #00D37F;
  border-radius: 67px;
  font-weight: 700;
  mix-blend-mode: normal;
}

.wealth_container .timePeriodWrapper .timePeriod span {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ebede9;
}

.wealth_container .timePeriodWrapper .timePeriod.active span {
  border-radius: 67px;
  color: #000 !important;
  font-weight: 700;
  mix-blend-mode: normal;
}

/* ======= our process css ====== */

.process_container {
  margin-top: 217.5px;
}

.process_container h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 160%;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.process_container .processCard {
  margin-top: 79px;
  background-color: transparent;
}

.process_container .processVector {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.process_container .processTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 170%;
  text-align: center;
  color: #cefd1e;
  padding-top: 26.77px;
}

.process_container .processContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 170%;
  text-align: center;
  color: #ffffff;
}

.process_container .processCard img {
  position: relative;
}
/* 
.process_container .addStepVector::after {
  content: '';
  width: 200px;
  height: 200px;
  position: absolute;
  background-image: url('../../assets/home/ourProcessVector.svg');
} */

/* ====== our client css ========= */

.client_container {
  margin-top: 250px;
}

.client_container h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
}

.client_container .clientTitle {
  padding-top: 35px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
}

.client_container .clientLogo {
  padding-top: 30px;
}

.client_container .clientLogo .clientCards {
  background: #ffffff;
  border-radius: 10px;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: space-around;
  height: 120px;
  /* padding: 20px 60px; */
}

.clientCardsImg {
  width: 100%;
  max-width: 150px;
  max-height: 100px;
}

/* ========== testimonial ===== */

.testimonial_container {
  margin-top: 200px;
  text-align: left;
  background: linear-gradient(180deg, #051213 0%, rgba(5, 18, 19, 0) 88.51%);
}

.testimonial_container h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #ffffff;
  padding-top: 120px;
}

.testimonial_container .subTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 160%;
  color: #ffffff;
  padding-top: 53px;
}

.testimonial_container .imgWrapper {
  position: relative;
}

.testimonial_container .btnImg {
  width: 169px;
  height: 60px;
}

.testimonial_container .homeBtn {
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0 10px;
}

.testimonial_container .testimonialCard {
  background: #082427;
  border-radius: 20px;
  padding: 28px;
  color: white;
  margin-top: 30px;
}

.testimonial_container .testimonialCard p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}

.testimonial_container .testimonialCard h5 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: left;
  color: #ffffff;
}

.testimonial_container .testimonialCard h6 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: left;
  color: #ffffff;
}

.testimonial_container .testimonialCard .profileImg {
  padding-top: 5px;
}

.testimonial_container .testimonialSlider {
  height: 1500px;
}

/* ============ faq ========= */

.faq_container {
  text-align: left;
  margin-top: 200px;
  margin-bottom: 210px;
}

.faq_container .faqTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 160%;
  /* identical to box height, or 96px */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  position: relative;

  color: #ffffff;
}

.faq_container .faqTitle::after {
  content: '';
  position: absolute;
  bottom: -17px;
  width: 147px;
  height: 17px;
  background-image: url('../../assets/home/headingLine.svg');
}

.faq_container .faqQunDiv {
  padding-top: 48px;
}

.faq_container .faqItems {
  background-color: #051213 !important;
  border-bottom: 1px solid #292e3c;
}

.faq_container .faqItems:hover {
  border-bottom: 1px solid #292e3c;
}

.faq_container .faqItems .show {
  border-bottom: 1px solid #292e3c;
}

.faq_container .faqHeader button {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: white !important;
  background-color: #051213 !important;
  padding-left: 0px;
  box-shadow: none;
}

.faq_container .faqHeader button[aria-expanded='true'] {
  /* .faq_container .faqHeader .accordion-button:not(.collapsed) { */
  color: #00d37f !important;
}

.faq_container .faqHeader button:focus {
  border: none !important;
}

.faq_container .faqHeader button::after {
  background-image: url('../../assets/home/accordianIconClose.svg') !important;
}

.faq_container .faqBody {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  color: #ffffff;
  background-color: transparent;
  border-left: 1px solid #cefd1e;
}

.faq_container .imgWrapper {
  /* position: relative; */
  text-align: center;
  margin-top: 50px;
}

.faq_container .btnImg {
  width: 169px;
  height: 60px;
}

.faq_container .homeBtn {
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0 10px;
}

.faq_container ul li::before {
  content: '\2022';
  color: white;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.faq_container ul li {
  padding-top: 10px;
}

/* ========= save more section ========= */

.saveMore_container {
  /* margin-top: 210px;
  margin-bottom: -10%; */
  background: transparent;
  z-index: 2;
  margin-top: -100px;
  margin-bottom: 100px;
}

.saveMore_container .saveMore_inner_container {
  background: linear-gradient(254.54deg, #00d37f -11.09%, #5c45cc 148.9%);
  border-radius: 10px;
  padding: 32px 47px;
}

.saveMore_container .textSection {
  text-align: left;
}

.saveMore_container .textSection h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  color: #ffffff;
  position: relative;
}
.saveMore_container .textSection h2 span {
  position: relative;
}
.saveMore_container .textSection h2 span::after {
  content: '';
  position: absolute;
  bottom: -19px;
  width: 100%;
  height: 17px;
  left: 0;
  background-image: url('../../assets/home/headingLine.svg');
  background-repeat: no-repeat;
}

.saveMore_container .textSection p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  color: #ffffff;
  padding-top: 30px;
}

.saveMore_container .imgWrapper {
  position: relative;
  text-align: right;
  /* margin-top: 50px; */
}

.saveMore_container .btnImg {
  width: 169px;
  height: 60px;
}

.saveMore_container .homeBtn {
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0 9px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .imgBuildingCenter {
    text-align: center;
  }
  .subHeading::after {
    width: 100px;
  }

  .wealth_container {
    /* height:100%;
    width:100%;  */
    background: #082427;
  }

  .wealth_vector {
    top: 70px;
  }

  .wealth_container .wealth_inner_container {
    text-align: center;
  }

  .price {
    text-align: center;
  }

  .wealth_container_bg::after {
    height: 0;
    content: '';
    width: 0;
    border-radius: 0;
  }
  .wealth_container_bg {
    height: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: 20px;
  }
  .wealth_container .wealth_inner_container {
    text-align: center;
  }

  .wealth_container .wealth_inner_container .wealthSlider {
    margin-top: 70px;
    text-align: left;
  }

  .wealth_container .wealth_inner_container h2 {
    font-size: 32px;
    line-height: 120%;
    padding-top: 0;
  }

  .wealth_container .wealth_inner_container p {
    font-size: 20px;
    line-height: 140%;
    padding-top: 30;
  }

  .wealth_container .wealth_inner_container .imgWrapper {
    padding-top: 30px;
  }

  .wealth_container .tabTitle {
    font-size: 16px;
    line-height: 27px;
  }

  .wealth_container .processSpace {
    padding-top: 57px;
  }

  .wealth_container .processPrice {
    font-size: 16px;
  }

  .wealth_container .tabContent {
    font-size: 16px;
    line-height: 140%;
    padding-top: 34px;
  }

  .wealth_container .monthlyInput {
    width: 115px;
  }

  .wealth_container .RateInput {
    width: 115px;
  }

  /* ======= our process css ====== */

  .process_container {
    margin-top: 50px;
  }

  .process_container h2 {
    font-size: 32px;
    line-height: 140%;
  }

  .process_container .processCard {
    margin-top: 20px;
  }

  .process_container .processTitle {
    font-size: 24px;
  }

  .process_container .processContent {
    font-size: 16px;
  }

  /* ====== our client css ========= */

  .client_container {
    margin-top: 50px;
  }

  .client_container h2 {
    font-size: 32px;
    line-height: 140%;
  }

  .client_container .clientTitle {
    font-size: 20px;
    line-height: 140%;
  }

  .client_container .clientLogo {
    padding-top: 20px;
  }

  .client_container .clientLogo .clientCards {
    background: #ffffff;
    border-radius: 10px;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: space-around;
    height: 120px;
    margin-top: 10px;
  }

  /* ========== testimonial ===== */

  .testimonial_container {
    margin-top: 50px;
  }

  .testimonial_container h2 {
    font-size: 32px;
    line-height: 120%;
    padding-top: 60px;
    text-align: center;
  }

  .testimonial_container .subTitle {
    font-size: 20px;
    line-height: 120%;
    padding-top: 20px;
  }

  .testimonial_container .testimonialSlider {
    height: 450px;
  }

  /* ============ faq ========= */

  .faq_container {
    margin: 50px 0;
  }

  .faq_container .faqTitle {
    font-size: 32px;
    line-height: 120%;
    justify-content: center;
  }

  .faq_container .faqTitle::after {
    width: 80px;
  }

  .faq_container .faqQunDiv {
    padding-top: 20px;
  }

  .faq_container .faqHeader button {
    font-size: 20px;
    line-height: 33px;
  }

  .faq_container .faqBody {
    font-size: 16px;
    line-height: 33px;
  }

  .faq_container .imgWrapper {
    margin-top: 20px;
  }

  /* ===========save more ========= */

  .saveMore_container {
    margin-top: 100px;
  }

  .saveMore_container .textSection h2 {
    font-size: 32px;
  }

  .saveMore_container .textSection h2::after {
    width: 100px;
    left: 5px;
  }

  .saveMore_container .textSection p {
    font-size: 20px;
  }

  .saveMore_container .imgWrapper {
    text-align: left;
  }
  .hideArrow {
    display: none;
  }
  .threeStepsImg {
    max-width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .imgBuildingCenter {
    text-align: center;
  }
  .price {
    text-align: center;
  }
  .clientCardsImg {
    max-width: 168px;
  }
  .subHeading::after {
    width: 100px;
  }

  .wealth_container {
    height: 100%;
    width: 100%;
    /* background: #082427; */
    background: #082427;
  }

  .wealth_vector {
    top: 70px;
  }

  .wealth_container .wealth_inner_container {
    text-align: center;
  }

  .wealth_container .wealth_inner_container .wealthSlider {
    margin-top: 70px;
    text-align: left;
  }

  .wealth_container .wealth_inner_container h2 {
    font-size: 32px;
    line-height: 120%;
    padding-top: 0;
  }

  .wealth_container .wealth_inner_container p {
    font-size: 20px;
    line-height: 140%;
    padding-top: 30;
  }

  .wealth_container .wealth_inner_container .imgWrapper {
    padding-top: 30px;
  }

  .wealth_container .tabTitle {
    font-size: 16px;
    line-height: 27px;
  }

  .wealth_container .processSpace {
    padding-top: 57px;
  }

  .wealth_container .processPrice {
    font-size: 16px;
  }

  .wealth_container .tabContent {
    font-size: 16px;
    line-height: 140%;
    padding-top: 34px;
  }

  .wealth_container .monthlyInput {
    width: 115px;
  }

  .wealth_container .RateInput {
    width: 115px;
  }


  /* ======= our process css ====== */

  .process_container {
    margin-top: 50px;
  }

  .process_container h2 {
    font-size: 32px;
    line-height: 140%;
  }

  .process_container .processCard {
    margin-top: 20px;
  }

  .process_container .processTitle {
    font-size: 24px;
  }

  .process_container .processContent {
    font-size: 16px;
  }
  /* ====== our client css ========= */

  .client_container {
    margin-top: 70px;
  }

  .client_container h2 {
    font-size: 32px;
    line-height: 140%;
  }

  .client_container .clientTitle {
    font-size: 20px;
    line-height: 140%;
  }

  .client_container .clientLogo {
    padding-top: 20px;
  }

  .client_container .clientLogo .clientCards {
    background: #ffffff;
    border-radius: 10px;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: space-around;
    height: 120px;
    margin-top: 5px;
  }

  /* ========== testimonial ===== */

  .testimonial_container {
    margin-top: 50px;
  }

  .testimonial_container h2 {
    font-size: 32px;
    line-height: 120%;
    padding-top: 60px;
    text-align: center;
    justify-content: center;
  }

  .testimonial_container .subTitle {
    font-size: 20px;
    line-height: 120%;
    padding-top: 20px;
  }

  .testimonial_container .testimonialSlider {
    height: 450px;
  }

  /* ============ faq ========= */

  .faq_container {
    margin: 70px 0;
  }

  .faq_container .faqTitle {
    font-size: 32px;
    line-height: 120%;
    justify-content: center;
  }

  .faq_container .faqTitle::after {
    width: 80px;
  }

  .faq_container .faqQunDiv {
    padding-top: 20px;
  }

  .faq_container .faqHeader button {
    font-size: 20px;
    line-height: 33px;
  }

  .faq_container .faqBody {
    font-size: 16px;
    line-height: 33px;
  }

  .faq_container .imgWrapper {
    margin-top: 30px;
  }

  /* ===========save more ========= */

  .saveMore_container {
    margin-top: 80px;
  }

  .saveMore_container .textSection h2 {
    font-size: 32px;
  }

  .saveMore_container .textSection h2::after {
    width: 100px;
    left: 15px;
  }

  .saveMore_container .textSection p {
    font-size: 20px;
  }

  .saveMore_container .imgWrapper {
    text-align: left;
  }
  .hideArrow img {
    display: none;
  }
  .threeStepsImg {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .clientCardsImg {
    max-width: 168px;
  }
  .subHeading::after {
    width: 100px;
  }

  .wealth_container {
    height: 100%;
    width: 100%;
    /* background: #082427; */
    background: #082427;
  }

  .wealth_vector {
    top: 70px;
  }

  .wealth_container .wealth_inner_container {
    padding: 50px 0;
    /* border-radius: 50% 50% 0% 0%; */
    /* height: 272px; */
  }

  .wealth_container .wealth_inner_container h2 {
    font-size: 32px;
    line-height: 120%;
    padding-top: 0;
  }

  .wealth_container .wealth_inner_container p {
    font-size: 20px;
    line-height: 140%;
    padding-top: 0;
  }

  .wealth_container .wealth_inner_container .imgWrapper {
    padding-top: 0;
  }

  .wealth_container .tabTitle {
    font-size: 16px;
    line-height: 27px;
  }

  .wealth_container .processSpace {
    padding-top: 57px;
  }

  .wealth_container .processPrice {
    font-size: 16px;
  }

  .wealth_container .tabContent {
    font-size: 16px;
    line-height: 140%;
    padding-top: 34px;
  }

  .wealth_container .monthlyInput {
    width: 115px;
  }

  .wealth_container .RateInput {
    width: 115px;
  }

  /* ======= our process css ====== */

  .process_container {
    margin-top: 70px;
  }

  .process_container h2 {
    font-size: 32px;
    line-height: 140%;
  }

  .process_container .processCard {
    margin-top: 40px;
  }

  .process_container .processTitle {
    font-size: 24px;
  }

  .process_container .processContent {
    font-size: 16px;
  }
  /* ====== our client css ========= */

  .client_container {
    margin-top: 70px;
  }

  .client_container h2 {
    font-size: 32px;
    line-height: 140%;
  }

  .client_container .clientTitle {
    font-size: 20px;
    line-height: 140%;
  }

  .client_container .clientLogo {
    padding-top: 50px;
  }

  .client_container .clientLogo .clientCards {
    background: #ffffff;
    border-radius: 10px;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: space-around;
    height: 120px;
    margin-top: 5px;
  }

  /* ========== testimonial ===== */

  .testimonial_container {
    margin-top: 90px;
  }

  .testimonial_container h2 {
    font-size: 32px;
    line-height: 120%;
    padding-top: 60px;
  }

  .testimonial_container .subTitle {
    font-size: 20px;
    line-height: 120%;
    padding-top: 20px;
  }

  /* ============ faq ========= */

  .faq_container {
    margin: 90px 0;
  }

  .faq_container .faqTitle {
    font-size: 32px;
    line-height: 120%;
  }

  .faq_container .faqTitle::after {
    width: 80px;
  }

  .faq_container .faqQunDiv {
    padding-top: 50px;
  }

  .faq_container .faqHeader button {
    font-size: 20px;
    line-height: 33px;
  }

  .faq_container .imgWrapper {
    margin-top: 30px;
  }

  .faq_container .faqBody {
    font-size: 16px;
    line-height: 33px;
  }

  /* ===========save more ========= */

  .saveMore_container {
    /* margin-top: 70px; */
  }

  .saveMore_container .textSection h2 {
    font-size: 32px;
  }

  .saveMore_container .textSection h2::after {
    width: 100px;
    left: 173px;
  }

  .saveMore_container .textSection p {
    font-size: 20px;
  }

  .saveMore_container .imgWrapper {
    text-align: left;
  }
  .hideArrow img {
    display: none;
  }
  .threeStepsImg {
    max-width: 100%;
  }
}

@media (min-width: 1030px) and (max-width: 1366px) {
  .saveMore_container .textSection h2 {
    font-size: 36px;
  }
  .wealth_container {
    /* height:100%;
    width:100%;  */
    background-size: 1280px 879px;
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1605px) {
  .saveMore_container .textSection h2 {
    font-size: 40px;
  }

  .wealth_container {
    background-size: cover;
  }
}

@media screen and (min-width: 1900px) {
  .wealth_container {
    background-size: cover;
  }
}

/* ======= ipad air landscape ========= */

@media all and (device-width: 1180px) and (orientation: landscape) {
  .wealth_container {
    background-size: 1180px 1028px;
  }

  .wealth_container .wealth_inner_container h2 {
    font-size: 32px;
  }

  .wealth_container .wealth_inner_container p {
    font-size: 20px;
  }

  .wealth_container .processPrice {
    font-size: 24px;
  }

  .wealth_container .tabTitle {
    font-size: 18px;
  }

  .saveMore_container .saveMoreSecondContent {
    position: absolute;
    left: -25px;
    bottom: 0;
  }
}
