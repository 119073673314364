.CustomBtn {
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  background: #00d37f;
  /* transition: all 1s ease; */
  background: linear-gradient(to right, #cefd1e 50%, #00d37f 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.CustomWhiteBtn {
  background: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
    /* transition: all 1s ease; */
    background: linear-gradient(to right, #cefd1e 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
}

.CustomWhiteBtn:hover {
  background-position: left bottom;
  /* background: #cefd1e; */
  box-shadow: 1px 1px 4px rgb(0 0 0 / 90%);
}

.CustomWhiteBtn button:hover {
  box-shadow: none !important;
}


.CustomWhiteBtn button {
  background: transparent;
  border-radius: 8px;
  height: inherit;
}

.CustomBtn button {
  background: transparent;
  border-radius: 8px;
  height: inherit;
}

.CustomBtn:hover {
  /* background: #cefd1e; */
  background-position: left bottom;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 90%);
}

.CustomBtn button:hover {
  box-shadow: none !important;
}

.CustomBtn img {
  border-radius: 8px;
  height: 100%;
}

.CustomWhiteBtn img {
  border-radius: 8px;
  height: 100%;
}
