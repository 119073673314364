.profile_container {
  color: white;
  background-color: #191919;
  padding: 1.5rem;
  min-height: calc(100vh - 69px);
}

.profile_container h4 {
  font-weight: 700;
  font-size: 1.75rem;
  text-align: left;
}

.settings_container {
  margin-top: 20px;
  display: flex;
  align-items: space-around;
}

.setting_wrap{
  display: flex;
  align-items: center;
}

.right_angles{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 0.3rem;
  display: none;
}
@media screen and (max-width: 767px) {
  .right_angles{
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .settings_container {
    flex-direction: column;
  }
}
