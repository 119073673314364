.aq_card {
  margin-right: 20px;
  margin-top: 20px;
  width: 170px;
  position: relative;
  background: #1d1d1d;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aq_card > img {
  width: 60%;
  height: 80%;
}

.aq_cardtitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
}

.aq_cardbottom {
  display: flex;
  justify-content: space-between;
}

.pnl {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-right: 20px;
  color: #bfc0c0;
}

.red {
  color: #c5283d;
}

.green {
  color: #00d37f;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
}

.doticon {
  height: 18px;
  filter: invert(81%) sepia(52%) saturate(632%) hue-rotate(21deg)
    brightness(105%) contrast(110%);
}

.aq_popup {
  position: absolute;
  right: 15px;
  top: 15px;
}
.aq_popup:hover {
  cursor: pointer;
}

.menu {
  background: #0f0e0e;
  border-radius: 6px;
}

.menuItem {
  display: flex;
  padding: 2px 0px;
}
.dropItem:hover {
  background: #0f0e0e;
}

.menuItemIcon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #cefd1e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuItemIcon > img {
  width: 10px;
  height: 10px;
}

.menuItemTitle {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}

@media screen and (max-width: 760px) {
  .aq_card {
    width: 100%;
  }
}
