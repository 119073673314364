.setting {
  max-width: 370px;
  /* height: 417px; */
  /* background: #1d1d1d; */
  border-radius: 6px;
  width: 100%;
}

.close_icon{
  display: none;
}

@media screen and (max-width: 767px) {
  .setting{
    max-width: 300px;
    position: fixed;
    left: 0%;
    transition: all 0.5s ease-in-out;
    transform: translateX(-100%);
    z-index: 10;
    top: 0px;
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .setting{
    background-color: #191919;
    height: calc(100vh);

  }
  .close_icon{
    position: absolute;
    right: 15px;
    top: 12px;
    display: block;
  }

}

