.post {
  background: #1d1d1d;
  border-radius: 6px;
  width: 40%;
  margin: 20px 30px 0px 30px;
}

.post_header {
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid #333333;
}

.post_header > img {
  padding: 10px;
  width: 60px;
  height: 60px;
  background-color: black;
  border-radius: 50%;
  margin-right: 20px;
}

.aq_popup {
  position: absolute;
  right: 25px;
  top: 35px;
}
.aq_popup:hover {
  cursor: pointer;
}

.doticon {
  height: 18px;
  filter: invert(81%) sepia(52%) saturate(632%) hue-rotate(21deg)
    brightness(105%) contrast(110%);
}

.menu {
  background: #0f0e0e;
  border-radius: 6px;
}

.menuItem {
  display: flex;
  padding: 2px 0px;
}
.dropItem:hover {
  background: #0f0e0e;
}

.menuItemIcon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #cefd1e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuItemIcon > img {
  width: 10px;
  height: 10px;
}

.menuItemTitle {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
}

.post_img {
  margin: 20px 0px;
}

.post_img > img {
  width: 90%;
}

.post_act_like {
  display: flex;
  justify-content: space-between;
  margin: 20px 25px;
}

.post_actions > img {
  margin: 0px 5px;
}

.likes {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  text-align: right;

  color: #bfc0c0;
}

.post_caption {
  margin: 0 25px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  color: #ffffff;
  padding-bottom: 20px;
  border-bottom: 1px solid #333333;
}

.add_comment {
  margin: 15px 25px 0px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.com_inp {
  display: flex;
  align-items: center;
}

.addcom {
  margin-left: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #bfc0c0;
}

.post_comment {
  background: #00d37f;
  border-radius: 50%;
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.post_comment > img {
  /* display: block;
  margin: auto; */
  padding: 5px;
  padding-left: 10px;
}
