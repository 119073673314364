.suggest {
  width: 400px;
  background: #1d1d1d;
  border-radius: 6px;
  height: 100%;
  padding: 20px;
}

.sugg_head {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-right: 60px;
  margin-bottom: 10px;
  color: #ffffff;
}

.fb_text {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;

  color: #ffffff;
}

.fb_link {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;

  color: #00d37f;
}
.facebook {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

.logo {
  background: #cefd1e;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: black;
  margin-right: 17px;
  display: flex;
  justify-content: center;
}

.logo > img {
  margin: auto;
}

.fb1 {
  display: flex;
  align-items: center;
}

.fb_link {
  cursor: pointer;
}
