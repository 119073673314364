.card {
  margin: 0px 30px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #333333;
}
.start {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #191919;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 6px;
  width: 60px;
  height: 60px;
}

.icon img {
  width: 40px;
  height: 40px;
}

.title {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  color: #ffffff;
}

.btn {
  width: 106px;
  height: 45px;

  border: 1px solid #00d37f;
  box-sizing: border-box;
  border-radius: 6px;
  color: #00d37f;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  background: #00d37f;
  cursor: pointer;
  color: #ffffff;
}

.selected {
  background: rgba(0, 211, 127, 0.1);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected img {
  filter: invert(57%) sepia(64%) saturate(2903%) hue-rotate(118deg)
    brightness(103%) contrast(101%);
}

.title > a:hover {
  color: #00d37f;
}
