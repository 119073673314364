.db_container {
    width: 350px;
    background: #1D1D1D;
    border-radius: 6px;
    padding: 1rem;
}

.db_title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
}

.db_title h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
}

.db_subcontent h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.db_subcontent {
    padding: 1rem;
}

.db_subcontent h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #BFC0C0;
    text-align: left;
}

.first_one,
.second_one {
    display: flex;
    text-align: center;
    justify-content: space-between;
}

.third_one,
.fourth_one {
    display: flex;
    text-align: center;
    justify-content: stretch;
}

._text {
    padding-left: 2rem;
}

.initial {
    display: flex;
    justify-content: space-between;
}

a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #BFC0C0;
    text-decoration: none;
    padding: 0.4rem;
}

li {
    text-align: left;
    list-style: none;
}

.notifications {
    padding-top: 2rem;
}

@media screen and (max-width: 1300px) {
    .notifications .initial h4 {
        font-size: 20px;
    }
    .notifications .initial {
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 420px) {
    .db_container {
        width: 300px;
        padding: 2rem;
    }
}

@media screen and (max-width: 360px) {
    .db_container {
        width: 280px;
        padding: 1rem;
    }
}