.right_secn {
  width: 100%;
  margin-left: 30px;
  padding-bottom: 40px;
  background: #1d1d1d;
  border-radius: 6px;
}

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  color: #ffffff;
  text-align: start;
  margin: 20px 30px 0px;
}
