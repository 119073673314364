.TestimonialWrapper {
  padding-bottom: 200px;
  text-align: left;
}

.TestimonialWrapper p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #e5e5e5;
}

.TestimonialWrapper h1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 96px;
  letter-spacing: 0.01em;

  color: #ffffff;
}



/* savemore section */

.saveMore_container {
  /* margin-top: 210px;
    margin-bottom: -10%; */
  background: transparent;
  z-index: 2;
  margin-top: -100px;
  margin-bottom: 140px;
}

.saveMore_container .saveMore_inner_container {
  background: linear-gradient(254.54deg, #00d37f -11.09%, #5c45cc 148.9%);
  border-radius: 10px;
  padding: 32px 47px;
}

.saveMore_container .textSection {
  text-align: left;
}

.saveMore_container .textSection h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  color: #ffffff;
  position: relative;
}
.saveMore_container .textSection h2 span {
  position: relative;
}
.saveMore_container .textSection h2 span::after {
  content: '';
  position: absolute;
  bottom: -19px;
  width: 100%;
  height: 17px;
  left: 0;
  background-image: url('../../../../assets/home/headingLine.svg');
  background-repeat: no-repeat;
}

.saveMore_container .textSection p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  color: #ffffff;
  padding-top: 30px;
}

.saveMore_container .imgWrapper {
  position: relative;
  text-align: right;
  /* margin-top: 50px; */
}

.saveMore_container .btnImg {
  width: 169px;
  height: 60px;
}

.saveMore_container .homeBtn {
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0 9px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .imgBuildingCenter {
    text-align: center;
  }
  .subHeading::after {
    width: 100px;
  }

  .wealth_container {
    /* height:100%;
      width:100%;  */
    background: #082427;
  }

  .wealth_vector {
    top: 70px;
  }

  .wealth_container .wealth_inner_container {
    text-align: center;
  }

  .price {
    text-align: center;
  }

  .wealth_container_bg::after {
    height: 0;
    content: '';
    width: 0;
    border-radius: 0;
  }
  .wealth_container_bg {
    height: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: 20px;
  }
  .wealth_container .wealth_inner_container {
    text-align: center;
  }

  .wealth_container .wealth_inner_container .wealthSlider {
    margin-top: 70px;
    text-align: left;
  }

  .wealth_container .wealth_inner_container h2 {
    font-size: 32px;
    line-height: 120%;
    padding-top: 0;
  }

  .wealth_container .wealth_inner_container p {
    font-size: 20px;
    line-height: 140%;
    padding-top: 30;
  }

  .wealth_container .wealth_inner_container .imgWrapper {
    padding-top: 30px;
  }

  .wealth_container .tabTitle {
    font-size: 16px;
    line-height: 27px;
  }

  .wealth_container .processSpace {
    padding-top: 57px;
  }

  .wealth_container .processPrice {
    font-size: 16px;
  }

  .wealth_container .tabContent {
    font-size: 16px;
    line-height: 140%;
    padding-top: 34px;
  }

  .wealth_container .monthlyInput {
    width: 115px;
  }

  .wealth_container .RateInput {
    width: 115px;
  }
}
