a.active .sidebaritem_wrap {
    cursor: pointer;
    color: #00d37f;
    background: linear-gradient(90deg,
            rgba(0, 211, 127, 0.1) 0%,
            rgba(0, 211, 127, 0) 100%);
}

a.active .sidebaritem_wrap > div img {
    filter: invert(50%) sepia(30%) saturate(4500%) hue-rotate(111deg) brightness(96%) contrast(101%);
}