.last_token{
    display: flex;
    justify-content: flex-end;
}
.last_token p{
    color: #BFC0C0;
}
.auto_title{
    margin-top: 10px;
    text-align: left;
    color: #FFFFFF;
    min-width: 100% !important;
    padding-left: 30px;
}
.auto_title h4{
    font-size: 14px;
    color: #fff;
    margin-bottom: 15px;
}
.copy_icon{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    min-width: unset;
}
.fyers_dec{
    padding: 20px 0;
    padding-left: 30px;
}
.fyers_dec p{
    font-size: 16px;
    color: #BFC0C0;
    margin-bottom: 10px;
}
.fyers_dec span{
    color: #00D37F;
}
.popup-content{
    border: unset;
}
.popup-overlay {
    z-index: 99999 !important;
}

@media screen and (max-width: 1199px) {
    .popup-content {
        max-width: calc(100% - 30px) !important;
    }

}