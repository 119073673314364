.settings_card {
  width: 100%;
  height: 82px;
  display: flex;
  align-items: center;
  border-bottom: solid 3px #191919;
  justify-content: space-around;
  background: #1d1d1d;
  border-radius: 6px 6px 0px 0px;
}

.settings_card:hover {
  cursor: pointer;
}
.selected {
  background: #1d1d1d;
  background: linear-gradient(
    90deg,
    rgba(0, 211, 127, 0.1) 0%,
    rgba(29, 29, 29, 0.1) 90.16%
  );
}

.icon {
  width: 40px;
  height: 40px;
  background: #cefd1e;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon img {
  filter: invert(12%) sepia(0%) saturate(1%) hue-rotate(151deg) brightness(97%)
    contrast(101%);
}

.details {
  width: 60%;
  text-align: left;
}

.card_heading {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 3px;
  color: #ffffff;
}

.card_subheading {
  margin-bottom: 0px;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #bfc0c0;
}

.arrow_icon img {
  filter: invert(65%) sepia(18%) saturate(5326%) hue-rotate(111deg)
    brightness(95%) contrast(102%);
  /* https://isotropic.co/tool/hex-color-to-css-filter/ */
  /* https://codepen.io/sosuke/pen/Pjoqqp */
}

@media screen and (max-width: 900px) {
  .settings_card {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .card_heading {
    font-size: 14px;
  }
  .settings_card {
    height: 75px;
  }
}