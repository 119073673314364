.ab_card {
  background: linear-gradient(122.75deg, #191f20 -43.98%, #204348 100%);
  border-radius: 6px;
  padding: 2rem;
  
font-family: 'HK Grotesk';
font-style: normal;
}
.ab_header {
}
.inner_card {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.image {
  flex-basis: 50%;
}

.image img {
  width: auto;
}

.text {
  padding-left: 1rem;
}
h6 > .text {
  font-size: 0.4rem;
}
.inner_card img {
  max-width: 100%;
}

.ab_card h5 {
  font-weight: 400;
  color: #bfc0c0;
  font-size: 0.875rem;
  font-family: HKG-reg;
}

.ab_card h6 {
  font-weight: 700;
  color: #ffffff;
  font-size: 1.375rem;
  font-family: 'HKG-bold';
}

.ab_card .h6down_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: black solid 1px;
  padding-right: 2.5rem;
}

.ab_card .h5down_section {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
font-family: 'HK Grotesk';
font-style: normal;
}

.acc_mr{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .ab_card {
    display: block;
  }
}
