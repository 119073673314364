.carditem {
  padding: 1.5rem 1rem;
  background: #E6FBF2;
  border-radius: 10px;
  border:1px solid transparent;
  box-shadow: 21px 36px 82px 0px #90B1158F;
}

.carditem h3 {
  font-family: var(--hkgmed);
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: -0.005em;
  color: #303030;
  margin-bottom:1rem;
}

.carditem p {
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #474747;
  line-height: 1.4;
  margin-bottom:2rem;
}

.carditem img {
  width: 100%;
  object-fit: cover;
  max-height:300px;
}

@media screen and (max-width:900px){
  .carditem h3{
    font-size:1.5rem
  }

  .carditem p{
    font-size:0.95rem;
  }
}

@media screen and (max-width:600px){
  .carditem h3{
    font-size:1.3rem
  }

  .carditem p{
    font-size:0.85rem;
  }
}
