.right_secn {
  width: 100%;
  /* margin-left: 30px; */
  padding-bottom: 40px;
  background: #1d1d1d;
}

.title {
  padding: 10px;
  padding-left: 25px;
  text-align: start;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;

  color: #ffffff;
}
