.modal {
  background: #1d1d1d;
  border-radius: 6px;
}

.header {
  padding: 10px 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #5a5a5a;
}

.header_text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}

.close:hover {
  cursor: pointer;
}

.close > span {
  padding: 0px 4px;
  border: 1px solid #cefd1e;
  border-radius: 50%;
  color: #cefd1e;
  text-align: center;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

.inputs div {
  min-width: 310px;
  width: 27vw;
  max-width: 700px;
  /* margin: 5px; */
}

.modal_header_broker {
  margin-left: 35px;
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}

.save {
  padding: 0.5rem 5rem;
  font-size: 1.2 rem;
  background-color: #00d37f;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  color: white;
  letter-spacing: 1;
}

.cancel {
  margin-right: 15px;
  padding: 0.5rem 5rem;
  font-size: 1.2 rem;
  background-color: #ee6461;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  color: white;
  letter-spacing: 1;
}

.buttons {
  padding-top: 20px;
  border-top: 1px solid #5a5a5a;
  padding-right: 50px;
  text-align: end;
  margin-bottom: 20px;
}

.icon {
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #191919;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 6px;
  width: 60px;
  height: 60px;
}

.icon img {
  width: 40px;
  height: 40px;
}

.icon:hover {
  cursor: pointer;
}

.btn {
  width: 106px;
  height: 45px;

  border: 1px solid #00d37f;
  box-sizing: border-box;
  border-radius: 6px;
  color: #00d37f;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  background: #00d37f;
  cursor: pointer;
  color: #ffffff;
}

.errors{
  color: rgba(255,255,255,0.5)
}