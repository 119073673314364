.table {
    background-color: #1D1D1D;
    padding: 1.5rem 1rem 0;
    border-radius: 0.5rem;
    color: white;
    margin-bottom: 1.2rem;
    /* overflow: visible; */
}

.table .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    padding-bottom: 1.5rem;
    overflow: visible;
}

.table .heading>div {
    display: flex;
    align-items: flex-start;
    text-align: left;
    gap: 0.5rem;
    overflow: visible;
}

/* .table .heading:last-child{
    overflow: visible;
} */

.table .flex {
    display: flex;
    align-items: center;
    gap: 0.7rem
}

.table .view_more {
    display: flex;
    justify-content: space-between;
    color: #BFC0C0;
    background-color: #212121;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    margin: 0 -1rem;
    margin-bottom: 0;
}

.table .Arrow img {
    cursor: pointer;
}

.table .button {
    outline: none;
    border: none;
    background-color: #00D37F;
    color: white;
    padding: 0.2em 0.7em;
    border-radius: 0.6rem;
    margin-top: 0.2rem;
}

.table .heading>div.align {
    align-items: center;
    gap: 0.7rem;
}

.table .container {
    background-color: black;
    padding: 0.7rem 0.5rem;
    border-radius: 10px 10px;
}


.table .heading .text {
    font-size: 0.875rem;
    color: #BFC0C0;
    margin-bottom: 0.3rem;
}

.table>div:last-child {
    overflow: hidden;
}

/* .table table{
    table-layout: fixed;
    width: 100%;
} */

.table .doticon {
    height: 18px;
    /* width: 18px; */
    filter: invert(81%) sepia(52%) saturate(632%) hue-rotate(21deg) brightness(105%) contrast(110%);
    cursor: pointer;
}

.table .right {
    width: 5%;
}

.table input[type="checkbox"] {
    /* background-color: white; */
    background: white;
}

.table .price {
    color: #C5283D;
}

.table .profit {
    color: #00D37F;
}


.table td,
.table th {
    color: white;
    font-size: 0.875rem;
    text-align: left;
    white-space: nowrap;
}

.table th {
    border-bottom: 2px solid #333333;
    color: #BFC0C0;
}

.table td {
    border-bottom: none;
}

.table td input {
    background-color: transparent;
    border: 0;
    outline: 0;
}

.table .menu {
    background: #0f0e0e;
    border-radius: 6px;
    /* min-width: auto; */
}

.table .menuItem {
    display: flex;
    padding: 2px 0px;
    width: auto;
    color: white;
    padding: 0.3rem 0;
    /* justify-content: space-around; */
}

.table .dropdown-menu {
    min-width: 5rem;
}

.table .dropItem {
    width: auto;
}

.table .dropItem:hover {
    background: #0f0e0e;
}

.table .menuItemIcon {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #cefd1e;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table .menuItemIcon>img {
    width: 10px;
    height: 10px;
}

.table .green {
    color: #00D37F;
}

.table .red {
    color: #C5283D;
}


@media screen and (max-width: 991px) {
    .table .heading {
        font-size: 14px;
        flex-wrap: wrap;
        position: relative;
    }
    .table .heading > div.align{
        width: 100%;
    }
    .table .heading > div:nth-child(2),
    .table .heading > div:nth-child(3),
    .table .heading > div:nth-child(4){
        width: 33.33%;
        margin: 15px 0;
    }
    .table .heading > div:last-child{
        position: absolute;
        right: 0px;
        top: 0px;
    }
}

@media screen and (max-width: 767px) {
    .table .heading > div:nth-child(2),
    .table .heading > div:nth-child(3),
    .table .heading > div:nth-child(4),
    .table .heading > div:nth-child(5){
        width: 50%;
        margin: 15px 0;
    }
}
@media screen and (max-width: 480px) {
    .table .heading > div:nth-child(2),
    .table .heading > div:nth-child(3),
    .table .heading > div:nth-child(4),
    .table .heading > div:nth-child(5){
        width: 100%;
        margin: 10px 0;
    }
}