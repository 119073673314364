.storiesCard {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background: #1d1d1d;
  border-radius: 6px;
  height: 160px;
  margin: 0px 30px;
}

.story {
  margin: 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90%;
}

.story > img {
  width: 84px;
  height: 84px;
  border-radius: 50%;

  object-fit: cover;
  border: 1px solid rgba(0, 211, 127, 0.3);
  padding: 4px;
}

.st_caption {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
