.process {
  text-align: left;
  padding-top: 97px;
  /* padding: 3rem 0 3rem; */
}

.process .ways {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 160%;
  padding-top: 75px;
  letter-spacing: 0.01em;

  color: #ffffff;
}

.process .subHeading {
  color: #00d37f;
  position: relative;
}

.process .subHeading2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 160%;
  /* identical to box height, or 58px */
  text-align: center;
}

/* .process .subHeading::after {
      content: '';
      position: absolute;
      right: 0;
      width: 191px;
      bottom: 0;
      height: 17px;
      background-image: url('../../assets/home/headingLine.svg');
    } */

.bgTitleLine {
  position: relative;
}

.bgTitleLine::after {
  content: '';
  position: absolute;
  right: 0;
  width: 150px;
  bottom: -17px;
  height: 17px;
  background-image: url('../../../../../assets/home/headingLine.svg');
  background-repeat: no-repeat;
}

.process .investTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 160%;
  /* identical to box height, or 48px */
  padding-top: 40px;

  color: #00d37f;
}

.process .investCard {
  background-color: transparent;
}

.process .investContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  /* or 32px */
  min-height: 180px;
  color: #ffffff;
}

.process .investBest {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  /* or 32px */
  padding-top: 52px;

  color: #cefd1e;
}

.process h2 {
  text-align: center;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin: 0 100px;
}

.process p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 140%;
  /* or 45px */

  letter-spacing: 0.01em;

  color: #ffffff;
}
.cardRow {
  margin: 48px;
}

.investCardImgs {
  background-size: contain;
  background-repeat: no-repeat;
}

.cardImgContainer {
  width: 370px;
  height: 370px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .process .investCardImgs::after {
      content:"- Remember this" ;
      width:100%;
      position: absolute;
      background-image: url('../../assets/home/simulateBg.svg');
    } */

/* .process .flex {
      display: flex;
      gap: 2rem;
    }
    
    .process .flex > div {
      width: 100%;
    } */

@media (min-width: 320px) and (max-width: 480px) {
  .process {
    margin-top: 20px;
    /* padding: 3rem 0 3rem; */
  }

  .process h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .process .subHeading {
    font-size: 32px;
  }

  .process p {
    font-size: 20px;
  }

  .process .ways {
    font-size: 26px;
    line-height: 120%;
  }

  .process p {
    font-size: 26px;
  }

  .bgTitleLine::after {
    width: 112px;
  }
  .cardImgContainer {
    width: 230px;
    height: 230px;
  }
  .cardRow {
    margin: 0;
  }
  .process h2 {
    margin: 0;
  }
  .process .subHeading2 {
    font-size: 22px;
    margin: 8px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .process {
    padding-top: 74px;
  }
  .invest_container .mainImg {
    padding-top: 20px;
  }
  .invest_container {
    padding-top: 0;
  }
  .invest_container .inner_container p {
    font-size: 1rem;
  }
  .process h2 {
    font-size: 32px;
    margin-bottom: 45px;
  }
  .process .subHeading {
    font-size: 32px;
  }
  .invest_container .inner_container h1 {
    font-size: 3.6em;
  }
  .invest_container .inner_container h5 {
    font-size: 0.8rem;
  }
  .process .investContent {
    min-height: 350px;
  }
  .process .ways {
    font-size: 26px;
    line-height: 120%;
  }
  .process p {
    font-size: 26px;
  }
  .cardImgContainer {
    width: 250px;
    height: 250px;
  }
  .cardRow {
    margin: 32px 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .process {
    padding-top: 70px;
    /* padding: 3rem 0 3rem; */
  }

  .investCardImgs {
    width: 100%;
    min-height: 200px;
  }

  .process .investContent {
    font-size: 20px;
    line-height: 160%;
  }
  .process .investContent {
    min-height: 350px;
  }

  .process h2 {
    font-size: 32px;
    margin-bottom: 45px;
  }

  .process p {
    font-size: 26px;
  }

  .process .ways {
    padding-top: 5px;
    font-size: 26px;
  }
  .cardImgContainer {
    width: 230px;
    height: 230px;
  }
  .cardRow {
    margin: 32px 0;
  }
}

@media (min-width: 1025px) and (max-width: 1300px) {
  .cardImgContainer {
    width: 300px;
    height: 300px;
  }
  .cardRow {
    margin: 0;
  }
}
