.card_layout {
    display: flex;
    padding: 3rem;
    align-content: space-between;
    color: black;
    gap: 5px;
}

.indiv {
    color: #ffffff;
    background: linear-gradient(117.06deg, #191919 -8.93%, #104f55 116.48%);
    border-radius: 4px;
    margin: 0.5rem auto;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 14.375rem;
}

.indiv:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

@media screen and (max-width: 1000px) {
    .card_layout {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 20px;
    }
    .indiv {
        padding: 20px;
        width: calc(33% - 2.5px);
        flex-shrink: 0;
    }
}

@media screen and (max-width: 800px) {
    .card_layout {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .indiv {
        width: calc(50% - 2.5px);
        padding: 20px;
    }
}

@media screen and (max-width: 600px) {
    .card_layout {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }
    .indiv {
        padding: 20px;
        width: 100%;
        margin-bottom: 1.5rem;
    }
}