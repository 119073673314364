.cashback_container {
    background: linear-gradient(254.54deg, #3E51A3 -11.09%, #00D37F 148.9%);
    border-radius: 6px;
}

.cb_title {
    text-align: left;
    padding-left: 1rem;
    padding-top: 1rem;
    float: left;
}

.cb_image {
    float: right;
    width: 50%;
}

.cb_button button {
    background: #CEFD1E;
    border-radius: 6px;
    width: 99px;
    height: 40px;
    left: 1365px;
    top: 273px;
    color: #191919;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
}

button {
    outline: 0;
    border: none;
}

.cashback_container .cb_title h5 {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: 22px;
}

.cb_title span {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
}

@media screen and (max-width: 1300px) {
    .cashback_container {
        width: 100%;
        display: flex;
        overflow: hidden;
    }
    .cb_image {
        margin-left: auto;
        float: none;
    }
    .cb_title {
        float: none;
    }
}