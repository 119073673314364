.subscriber_wrap {
    max-width: calc(100% - 370px);
    border-radius: 6px;
    width: 100%;
}
.cm_center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.subscriber_wrap p{
    font-size: 30px;
    font-weight: 500;
}
.change_password{
    text-decoration: none !important;
}