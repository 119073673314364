.blogContainer{
    background: linear-gradient(180deg, rgba(16, 79, 85, 0.04) 0%, rgba(16, 79, 85, 0.015) 100%);
    
}
.blogCardStyle{
    margin-bottom: 100px;
    margin-top: 50px;
}
@media (min-width: 320px) and (max-width: 480px) {
    .navbar-brand{
        margin-right: 0;
    }
}