
.createStrategy{
    text-align: left;
    padding: 1.5rem;
    
    background:#191919;
    min-height: 100vh;
    color: white;
    letter-spacing: 1.3px;
}

.createStrategy .chips svg:hover path{
    fill:#EE6461;
}

.createStrategy .advancedSettings{
    background: #1D1D1D;
    padding: 1rem 0.75rem;
    margin-top: 1.5rem;
    display: flex;
    gap: 1.2rem;
}

.createStrategy .advancedSettings > div{
    width: calc(50% - 0.5rem);
    flex-shrink:0;
}

.createStrategy .advancedSettings > div > div{
    margin-top: 1.5rem;
}

.createStrategy .advancedSettings .trades{
    width: 100%;
}

.createStrategy .advancedSettings .trades > *{
    display: flex;
    gap: 0.5rem;
}

.createStrategy .advancedSettings .trades > * > *{
    width: calc(50% - 0.25rem);
}


.createStrategy .marketSettings{
    background: #1D1D1D;
    padding: 1rem 0.75rem;
    margin-top: 1.5rem;
    display: flex;
    gap: 1.2rem;
}

.createStrategy .marketSettings > div{
    width: 100%;
}

.createStrategy .marketSettings .inputContainer{
    margin-bottom: 3rem;
}

.createStrategy .marketSettings .inputContainer > div{
    display: flex;
    align-items: center;
    margin-top: 0.6rem;
    gap: 3rem;
}

.createStrategy .marketSettings .inputContainer > div > div{
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.createStrategy .marketSettings .inputContainer  > .disabled{
    gap: 1.5rem;
    margin-top: 0;
}

.createStrategy .marketSettings .inputContainer > .disabled p{
    font-size: 0.9rem;
}

.createStrategy .marketSettings .inputContainer > .disabled > *{
    width: 100%;
}

.createStrategy .marketSettings .inputContainer > .disabled > * > *{
    width: 100%;
}
.createStrategy .marketSettings input[type="radio"]{
    margin-right: 0.5rem;
    transform: scale(1.1);
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    border: 1px solid #5a5a5a;
    border-radius: 50%;
    background-clip: content-box;
    padding: 2.5px;
    font-size: 1.1rem;
}

.createStrategy .field{
    width: 100%;
}
.createStrategy .marketSettings input[type='radio']:checked{
    background-color: #00d37f;
    border: 2px solid #00d37f;
}

.createStrategy .marketSettings p{
    font-size: 1rem;
    margin: 1rem auto;
    font-weight: 500;
}

.createStrategy .marketSettings label{
    cursor: pointer;
    margin: 0;
}

.createStrategy h4.heading{
    margin-top: 2rem;
}

.createStrategy .errors {
    margin: 0;
    padding: 0;
    color: rgb(249, 36, 36);
    font-size: 0.9rem;
    margin-top: 0.2rem;
    text-align: left;
}

.createStrategy .quantDetails{
    /* background-color: #333333; */
    background: #1D1D1D;
    padding: 1rem 0.75rem;
    margin-top: 1.5rem;
}

.createStrategy .StrategyDetails{
    background: #1D1D1D;
    padding: 1rem 0.75rem;
    margin-top: 1.5rem;   
}

.createStrategy .StrategyDetails > div:first-of-type{
    display: flex;
    width: 100%;
    gap: 1.75rem;
    margin-top: 2rem;
}

.createStrategy .StrategyDetails > div:first-of-type > * {
    width: 100%;
}

.createStrategy .StrategyDetails > div:first-of-type > div > *{
    width: 100%;
}

.createStrategy h5{
    font-size: 1.2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #BFC0C0;
}

.createStrategy .quantDetails > div:first-of-type{
    display: flex;
    width: 100%;
    gap: 1.75rem;
    margin-top: 2rem;
}

.createStrategy .quantDetails > div:first-of-type > * {
    width: 100%;
}

.createStrategy .quantDetails > div:first-of-type > div > *{
    width: 100%;
}

.createStrategy .description{
    margin-top: 2rem;
    width: 100%;
}

.createStrategy .description > div{
    width: 100%;
}



.createStrategy .createContainer{
    text-align: right;
    margin-top: 1.5rem;
}

.createStrategy .create{
    padding:0.5rem 5rem;
    font-size: 1.3rem;
    background-color: #00D37F;
    outline: none;
    border: none;
    border-radius: 0.3rem;
    color: white;
    letter-spacing: 1;
}

.createStrategy .tags{
    width: 100%;
    margin-top: 1.5rem;
}

.createStrategy .tags > div{
    width: 100%;
}

.createStrategy .pythonCode{
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.createStrategy .pythonCode > *:first-child{
    width: 80%;
}

.createStrategy .pythonCode *:first-child > *{
    width: 100%;
}

.createStrategy .pythonCode > *:last-child{
    width: calc(20% - 1rem);
}

.createStrategy .pythonCode > * button{
    width: 100%;
    padding: 0.5rem 0;
    margin: 0rem 0 0.8rem;
    outline: none;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: all 0.3s ease-in-out;
    font-size: 1.1rem;
}

.createStrategy .pythonCode > * button:hover{
    transform: scale(1.03);
}

.createStrategy .pythonCode > * .copy{
    background-color: transparent;
    border: 1px solid #00D37F;
    color: #00d37f;
}

.createStrategy .pythonCode > * .edit{
    background-color: #00d37f;
    color: white;
}


@media screen and (max-width:900px) {
    .createStrategy .marketSettings{
        flex-direction: column;
    }

    .createStrategy .advancedSettings{
        flex-direction: column;
    }

    .createStrategy .advancedSettings > div{
        width: 100%;
    }

}

@media screen and (max-width:450px) {
    .createStrategy .marketSettings .inputContainer  > .disabled{
        flex-direction: column;
    }

    .createStrategy .quantDetails > .quantDetails_desc:first-of-type{
        flex-direction: column;
    }

    .createStrategy .StrategyDetails > .StrategyDetails_desc:first-of-type{
        flex-direction: column;
    }

    .createStrategy .advancedSettings .trades > *{
        flex-direction: column;
    }

    .createStrategy .advancedSettings .trades > * > *{
        width: 100%;
    }

}