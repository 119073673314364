.activeQ {
  width: 100%;
}

.aq_cards {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  /* width: max(1000px) */
}

@media screen and (max-width: 760px) {
  .aq_cards {
    flex-direction: column;
  }
}
