.inputs {
  margin-left: 80px;
}

.inputs div {
  width: calc(70vw - 370px);
}

.save {
  padding: 0.5rem 5rem;
  font-size: 1.2 rem;
  background-color: #00d37f;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  color: white;
  letter-spacing: 1;
}

.cancel {
  margin-right: 15px;
  padding: 0.5rem 5rem;
  font-size: 1.2 rem;
  background-color: #ee6461;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  color: white;
  letter-spacing: 1;
}

.buttons {
  margin-top: 30px;
  margin-right: 50px;
  text-align: end;
}

@media screen and (max-width: 900px) {
  .inputs {
    margin-left: 0px;
  }
  .inputs div {
    width: 80vw;
    margin: 40px auto;
  }

  .buttons {
    margin: 0px;
    text-align: center;
  }
  .cancel {
    margin: 0px;
    margin-bottom: 20px;
  }
  .save {
    margin: 0px;
    /* width: 50vw; */
  }
}
