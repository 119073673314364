.body {
  padding: 14px 30px 30px 30px;
  width: 100%;
}

.market {
  width: 100%;
  margin: 0;
  padding: 0;
}

.overlay {
  position: fixed;
  display: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 499;
  cursor: pointer;
}
.discover {
  background-color: #1d1d1d;
  min-height: 100vh;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 1rem;
}

.banner {
  min-height: 220px;
  border-radius: 0.5rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../../assets/stock_banner.png);
  background-position: center right;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2rem;
  margin-bottom: 1.8rem;
}

.banner h1 {
  color: white;
}

.quant .heading {
  display: flex;
  color: white;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
}

.quant .heading button {
  margin-left: auto;
  padding: 0.6em 1.8em;
  background-color: #00d37f;
  border: 1px solid #00d37f;
  color: white;
  border-radius: 0.5em;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s;
}

.quant .heading button:hover {
  background-color: transparent;
}
.quant .heading button img {
  margin-right: 0.5rem;
}

@media screen and (max-width: 750px) {
  .banner {
    padding: 0;
    min-height: 180px;
  }

  .banner h1 {
    font-size: 1.4rem;
    padding: 0.3rem;
    line-height: 1.4;
    text-align: center;
  }

  .quant .heading button {
    font-size: 0.9rem;
  }

  .quant .heading h4 {
    font-size: 1.3rem;
  }
  .body {
    padding: 6px 22px 22px 22px;
  }
}

@media screen and (max-width: 450px) {
  .banner h1 {
    font-size: 1.25rem;
    padding: 0.3rem;
    line-height: 1.4;
    text-align: center;
  }

  .quant .heading button {
    font-size: 0.8rem;
  }

  .quant .heading h4 {
    font-size: 1.2rem;
  }
}
