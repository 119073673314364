.footer {
  /* padding: 3rem 0; */
  padding-top: 3rem;
  padding-bottom: 1rem;
  background-color: #082427;
  width: 100%;
  color: white;
  font-family: 'Manrope', sans-serif;

  background-size: contain;
  background-repeat: no-repeat;
  outline: none;
  border: 0;
}

.footerContainerFirst {
  /* margin-top: 10%; */
  margin-bottom: 100px;
}

.footer_bg {
  height: 300px;
  margin-bottom: -160px;

  border-radius: 0% 0% 100% 100%;
  /* transform: rotate(180deg); */
  background: #082427;
  position: relative;
}

.footer .firstDivPages {
  text-align: left;
}

.footer .secondDivPages {
  text-align: left;
  padding-left: 150px;
  /* padding-top: 23px; */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 266%;
  color: #ffffff;
}

.footer .secondDivTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  padding-left: 150px;
  text-align: left;
  color: #ffffff;
}

.footer .thirdDivPages {
  text-align: left;
  /* padding-top: 23px; */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 266%;
  color: #ffffff;
}

.footer .thirdDivTitle {
  visibility: hidden;
}

.footer .copyRights {
  font-family: 'Manrope' !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  opacity: 0.5;
}

.footer .about_brand {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: #ffffff;
  padding-top: 22px;
}

.footer .social_handles {
  display: flex;
  justify-content: flex-start;
  /* gap: 2rem; */
  font-size: 1rem;
  padding-top: 31px;
}

.footer .social_handles a {
  background-color: white;
  border-radius: 50%;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: space-around;
  margin-top: 5px;
  width: 46.5px;
  margin-left: 10px;
}

.footer .social_handles a img {
  width: 50%;
}

.footer .social_handles a:first-child {
  margin-left: 0;
}

.footer .lines {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .social_handles a:hover {
  background: #00d37f !important;
}

/* .footer .social_handles a,
.footer .social_handles a:link,
.footer .social_handles a:visited,
.footer .social_handles a:hover,
.footer .social_handles a:active {
  color: white;

  font-weight: 500;
  text-decoration: none;
} */

.footer .flex {
  display: flex;
}

.footer hr {
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* background-color: rgba(255, 255, 255, 0.1); */
  height: 0.5px;
  outline: none;
}

.footer .flex > * {
  width: 50%;
}

.footer .flex > div:first-child {
  text-align: left;
}

.footer .flex button {
  outline: none;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.7em 1.2em;
  background: #00d37f;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  border: none;
  font-weight: 700;
}

.footer .flex > div:first-child p {
  font-size: 1.5rem;
  padding: 0;
  font-weight: 700;
}

.footer .flex > div:first-child .sub_heading {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0.5rem;
}

.footer .flex img {
  margin-bottom: 1rem;
}

.footer hr {
  font-family: 'Manrope';
  outline: none;
  margin: 2rem 0 1rem;
  height: 0px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
}

.footer .address {
  font-size: 1rem;
  font-weight: 500;
  margin: 0.5rem;
}

.footer .flex > div:last-child {
  text-align: right;
}

.footer .FooterMenu {
  margin-bottom: 5px;
}
.FooterMenu:hover {
  color: #00d37f;
  cursor: pointer;
}

.footer .belowFooterContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* or 32px */

  text-align: center;
  margin-top: 24px;
  color: #ffffff;
}

.secondDivPages a{
  font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    text-align: left;
}

.thirdDivPages a{
  font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    text-align: left;
}

@media (min-width: 320px) and (max-width: 480px) {
  .footer {
    background-image: none !important;
  }
  .footer .secondDivPages {
    text-align: left;
    padding-left: 0;
    /* padding-top: 10px; */
  }

  .footer .secondDivTitle {
    padding-top: 50px;
    text-align: left;
    padding-left: 0;
  }

  .footer .thirdDivTitle {
    display: none;
  }

  .footer .social_handles a {
    margin-left: 10px;
  }

  .footerContainerFirst {
    margin-bottom: 0px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .footer {
    background-image: none !important;
  }
  .footer .secondDivPages {
    text-align: left;
    padding-left: 0;
    /* padding-top: 10px; */
  }

  .footer .secondDivTitle {
    padding-top: 50px;
    text-align: left;
    padding-left: 0;
  }

  .footer .thirdDivTitle {
    display: none;
  }

  .footer .social_handles a {
    margin-left: 10px;
  }
  .footerContainerFirst {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer .secondDivPages {
    text-align: left;
    padding-left: 0;
    /* padding-top: 10px; */
  }

  .footer .secondDivTitle {
    padding-top: 10px;
    text-align: left;
    padding-left: 0;
  }

  .footer .thirdDivTitle {
    display: none;
  }

  .footer .social_handles a {
    margin-left: 10px;
  }
  .footerContainerFirst {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 1280px) {
  .footer .social_handles a {
    margin-left: 10px;
  }
}
