.sidebar {
  display: flex;
  height: 100vh;
}

.sidebar > div:first-child a{
  padding: 0;
}
.logo {
  padding: 0.5rem 0.75rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.logo img {
  width: 78%;
}

.sidebar > div:first-child {
  background: #0f0e0e;
  height: 100%;
  padding: 2rem 0rem;
  width: 270px;
  z-index: 1000;
  overflow: auto;
}

.sidebar > div:last-child {
  width: calc(100% - 250px);
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 1024px) {
  .sidebar > div:first-child {
    position: fixed;
    left: -300px;
    transition: all 0.3s ease-in-out;
  }

  .sidebar > div:last-child {
    width: calc(100%);
  }
}
