.privacyPolicyBg{
    background: linear-gradient(180deg, rgba(16, 79, 85, 0.04) 0%, rgba(16, 79, 85, 0.015) 100%);
}

.privacyContent {
    padding: 150px 0 100px 0;
    text-align: left;
}

.privacyContent h2{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 120%;
    letter-spacing: 0.01em;
    padding-bottom: 15px;
}

li{
    padding-top: 20px;
}