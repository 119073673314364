.blogCard_container {
  margin-bottom: 50px;
  position: relative;
}

.mainCard {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.imgWrapper{
  position: relative;
}

.image {
  /* background: url(), #d9d9d9; */
  border-radius: 6px;
  width: 100%;
  height: 257px;
}

.textWrapper {
  text-align: left;
  padding-left: 10px;
}

.date {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-top: 11px;
  color: #181818;

  opacity: 0.8;
}

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  padding-top: 10px;
  color: #181818;
}

.description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  padding-top: 9px;
  color: #191919;
}

.readMore {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding-top: 9px;
  color: #00d37f;
}

.carReadMoreBtn {
  background: #00d37f;
  border-radius: 8px;
  width: 169px;
  height: 50px;
  margin: 48px;
}

.btnCard {
  width: 'auto';
  height: 35px;
  background: #cefd1e;
  border-radius: 17.5px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.subCardBtnText {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #191919;
}

.noDataFound {
  min-height: 400px;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: space-around;
  /* height: 100px; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); */
}

@media (min-width: 320px) and (max-width: 480px) {
  .navbar-brand{
    margin-right: 0;
  }
}

@media screen and (max-width: 900px) {
  .carditem h3 {
    font-size: 1.5rem;
  }

  .carditem p {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 600px) {
  .carditem h3 {
    font-size: 1.3rem;
  }

  .carditem p {
    font-size: 0.85rem;
  }
  
}
