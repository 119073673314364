.otp {
  padding: 1rem 0rem 0.5rem;
}

.otp .input {
  padding: 0.75rem 1rem;
  margin-bottom: 0.2rem;
  border-radius: 0.75rem;
  font-size: 1.4rem;
  color: black;
}

.width {
  width: 55%;
}

@media screen and (max-width: 1400px) {
  .width {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .width {
    width: 70%;
  }

  .otp .input {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 800px) {
  .width {
    width: 85%;
  }

  .otp .input {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  .width {
    width: 100%;
  }
}
