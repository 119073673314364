.pv_card {
    background: linear-gradient(122.75deg, #191F20 -43.98%, #204348 100%);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 642px;
}

.pv_card>div {
    width: 100%;
}

.pv_text {
    font-family: 'HK Grotesk';
    font-style: normal;
    text-align: left;
}

.combined {
    display: flex;
}


.pv_card .pv_text .h3 {
    font-weight: 600;
    font-size: 1rem;
    font-style: normal;
    line-height: 20.85px;
}

.pv_card .pv_text .h4 {
    font-style: normal;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
    font-family: 'HKG-bold';
}

.pv_card .pv_text .h5 {
    font-weight: 500;
    font-family: 'HKG-semibold';
    font-size: 0.875rem;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
}


.red {
    color: #c5283d;
}

.green {
    color: #00d37f;
}

.pv_card .pv_text .h6 {
    font-weight: 700;
    font-size: 1.375rem;
    font-family: 'HKG-bold';
}

.h5down_section>.h5 {

    color: #BFC0C0;
}

.h5down_section {
    border-right: black solid 1px;
    margin-right: 2rem;
}

.h6down_section>.h5 {
    color: #BFC0C0;
}

.pv_card .h5down_section>* {
    padding-right: 3rem;
}

.loading_widget img {
    width: auto;
}

@media screen and (max-width: 480px) {
    .pv_card h4 {
        font-size: 0.875rem;
    }

    .loading_widget img {
        width: 100px;
    }

    .pv_card .pv_text .h5 {
        font-size: 0.8rem;
    }

    .pv_card .pv_text .h6 {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 450px) {
    .pv_card {
        display: block;
    }

    .combined {
        display: flex;
        justify-content: space-between;
    }
}