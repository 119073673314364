.navbar{
    background-color: #242424;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2rem;
    gap: 1.5rem;
    align-items: center;
}

.navbar .notification{
    width: 18px;
    cursor: pointer;
}

.navbar .menu a.link{
    text-decoration: none;
    padding: 0;
}
.navbar .profile{
    background-color: #191919 !important;
    outline: none;
    border: none;
    margin: 0;
    padding: 0.4rem;
    box-shadow: none !important;
    display: flex;
    align-items: center;
}

.navbar .menu{
    background-color: #191919 !important;
}

.navbar .menu a{
    color: white;
    padding-top:0.9rem;
    padding-bottom: 0.9rem;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbar .menu a img{
    width: 20px;
    margin-right: 0.4rem;
}

.navbar .hamburger{
    display: none;
}
.navbar .hamburger svg{
    fill: white;
    cursor: pointer;
}

.navbar .menu a img.addBroker{
    width: 25px;
    margin-right: 0;
}

.navbar .menu a:hover,.navbar .menu a:active,.navbar .menu a:visited{
    background-color: #69CAD7;
}
.navbar .profile p{
    display: inline-block;
    max-width: 100px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.navbar .profile:focus{
    background-color: #191919 !important;
    outline: none;
    border: none;
    box-shadow: none !important;
}
.navbar .profile img{
    width: 20px;
    margin-right: 0.5rem;
}

.navbar .searchbar{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #BFC0C0;
}

.navbar .searchbar img{
    width: 16px;
    cursor: pointer;
}

.navbar .searchbar input{
    background-color: transparent;
    border: none;
    min-width: 250px;
}

.navbar .searchbar input:focus{
    border: none;
    outline: none;
}
.navbar .signup button{
    color: white;
    border: none;
    font-size: 1rem;
    background-color: transparent;
}

.navbar .signin button{
    color: white;
    background-color: #00d37f;
    padding: 0.6em 1.3em;
    font-size: 1rem;
    border-radius: 5px;
}

.navbar .logo{
    margin-right: auto;
    display: none;
}

.navbar .logo img{
    width: 150px;
}

@media screen and (max-width: 1024px) {
    .navbar .logo{
        display: block;
    }

    .navbar{
        gap: 0.7rem;
    }

    .navbar .hamburger{
        display: block;
    }

    .navbar .searchbar{
        display: none;
    }

    .navbar .signup button,.navbar .signin button{
        font-size: 0.9rem;
    }
}

@media screen and (max-width:450px){
    .navbar .logo img{
        width: 115px;
    }

    .navbar .signin button{
        padding: 0.3em 0.8em;
    }

    .navbar .profile p{
        max-width: 50px;
    }
}

@media screen and (max-width:350px) {
    .navbar .logo img{
        width: 110px;
    }

    .navbar .signup button,.navbar .signin button{
        font-size: 0.8rem;
    }
    
    .navbar .profile p{
        max-width: 25px;
    }
}