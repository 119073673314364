.change {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #00d37f;
}

.change:hover {
  cursor: pointer;
}

.cp_button {
  font-family: 'Roboto', Arial, sans-serif;
  color: #000000;
  cursor: pointer;
  padding: 0px 30px;
  display: inline-block;
  margin: 10px 15px;
  text-transform: uppercase;
  line-height: 2em;
  letter-spacing: 1.5px;
  font-size: 1em;
  outline: none;
  position: relative;
  font-size: 14px;
  border: 3px solid #cfcece;
  background-color: #ffffff;
  border-radius: 15px 15px 15px 15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cp_button:hover {
  border-color: #2980b9;
  cursor: pointer;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  margin: auto;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.cp_button > input[type='file'] {
  display: none;
}

.content {
  margin: 20px 0px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.content > img {
  width: 40%;
}

.close:hover {
  cursor: pointer;
}

.close > span {
  /* padding: 0px 4px;
  border: 1px solid #cefd1e;
  border-radius: 50%; */
  color: #1d1d1d;
  text-align: center;
}

.dropzone:hover {
  cursor: pointer;
  opacity: 0.9;
}

.dropzone {
  border: 1px dotted #cfcece;
  padding: 6rem;
  font-size: 15px;
  opacity: 0.7;
  margin: 1rem;
}
