.eibp_container {
    background: transparent;
    z-index: -2;
}

.eibp_container h2 {
    letter-spacing: -0.015em;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #E7EDEE;
    text-align: left;
    padding: 1.8rem 0;
    font-size: 3rem;
}

.eibp_container p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 140%;
    font-feature-settings: 'salt' on;
    color: #E7EDEE;
    text-align: left;
    padding-right: 4rem;
}

.eibp_container .flex {
    display: flex;
}

.eibp_container .flex>div {
    width: 100%;
}

.eibp_container .flex .tex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-right: 2rem;
    padding-left: 2rem;
}

.eibp_container .flex .img {
    margin: 10px 0 10px 0;
    margin-bottom: 4rem;
}

.eibp_container .flex .img img {
    min-height: 350px;
    object-fit: cover;
}

@media screen and (max-width: 1350px) {
    .img img {
        width: 100%;
    }
    .eibp_container .flex {
        padding: 3rem 0;
    }
    .eibp_container h2 {
        font-size: 48px;
    }
    .eibp_container p {
        font-size: 20px;
    }
}

@media screen and (max-width: 850px) {
    .eibp_container .flex {
        padding: 10px;
    }
    .eibp_container .flex {
        padding: 4rem 0;
    }
    .eibp_container h2 {
        font-size: 36px;
    }
    .eibp_container p {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .eibp_container .flex {
        flex-direction: column-reverse;
    }
    .eibp_container .flex .tex {
        align-items: center;
    }
    .eibp_container p {
        text-align: center;
        font-size: 1.3rem;
        padding-right: 0;
    }
    .eibp_container h2 {
        text-align: center;
        font-size: 1.8rem;
    }
}