.blogContent {
  text-align: left;
  margin-top: 20px;
}

.btnCard {
  width: 102px;
  height: 35px;
  background: #cefd1e;
  border-radius: 17.5px;
}

.subCardBtnText {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #191919;
}

.blogContent .date {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #181818;
  padding-top: 23px;
  opacity: 0.8;
}

.blogContent .title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 120%;
  padding-top: 13px;
  color: #181818;
}

.blogContent .subHeading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  padding-top: 30px;
  color: rgba(24, 24, 24, 0.7);
}

.blogContent .img {
  padding-top: 39px;
  width: 100%;
  border-radius: 10px;
}

.blogContent .subTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  padding-top: 29px;
  color: #181818;
}

.blogContent .description1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 170%;
  padding-top: 19px;
  color: #191919;
}

.blogContent .List {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 112%;
  padding-top: 30px;
  color: #191919;
}

.blogContent .description2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 170%;
  padding-top: 40px;
  color: #191919;
}

.blogContent .subTitle1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  padding-top: 50px;
  color: #181818;
}

.blogContent .List1Title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 170%;
  padding-top: 33px;
  color: #191919;
}

.blogContent .List1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 170%;
  padding-top: 33px;
  color: #191919;
}

.blogContent .description3 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 170%;
  padding-top: 33px;
  color: #191919;
}

.blogContent ul li {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 170%;
  padding-top: 33px;
  color: #104f55;
}

.blogContent .listTitleMain1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 160%;
  /* or 51px */
  color: #191919;
}

.blogContent .listcontentMain1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 170%;
  /* or 37px */
  padding-top: 16px;

  color: #191919;
}

.blogContent .listImgText {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  /* or 29px */
  padding-top: 50px;

  color: #191919;
}

.blogContent .listImgBlowContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 170%;
  padding-top: 90px;
}

.blogContent .listImgSubBelowContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 170%;
  padding-top: 90px;
  color: #104f55;
}

.blogContent .portfolioTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;

  color: #181818;
}

.blogContent .portfolioContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  padding-top: 22px;
  color: #191919;
}

.blogContent .portfolioLinks {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 160%;
  padding-top: 31px;
  color: #00d37f;
}

.blogPopular {
  padding-top: 50px;
}

.blogPopular .btnCard1 {
  background: rgba(16, 79, 85, 0.05);
  border-radius: 15px;
  margin-left: 15px;
}

.blogPopular .btnCard1Text {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  color: #104f55;
}

.blogPopular .mainTitle {
  text-align: left;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  padding-top: 117px;
  color: #181818;
}

.blogPopular .PopularBlogsCard {
  padding-top: 24px;
  padding-bottom: 55px;
}

.mainCard {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  /* height: 485px; */
  padding: 10px;
}

.imgWrapper {
  position: relative;
}

.image {
  /* background: url(), #d9d9d9; */
  border-radius: 6px;
  width: 100%;
  height: 257px;
}

.textWrapper {
  text-align: left;
  padding-left: 10px;
}

.date {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-top: 11px;
  color: #181818;

  opacity: 0.8;
}

.title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  padding-top: 10px;
  color: #181818;
}

.description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  padding-top: 9px;
  color: #191919;
}

.readMore {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding-top: 9px;
  color: #00d37f;
}

@media (min-width: 320px) and (max-width: 480px) {
  .blogContent .title{
    font-size: 2rem;
  }
  .blogContent .subHeading {
    font-size: 1.6rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .blogContent .title{
    font-size: 2rem;
  }
  .blogContent .subHeading {
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .blogContent .title{
    font-size: 2.5rem;
  }

  .blogContent .subHeading {
    font-size: 2rem;
  }
}