.navbarCustom {
  padding: 1.6rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 2rem;
  background: #ffffff !important;
}

.navbarHome hr {
  height: 0px;
  border: 1px solid rgba(166, 185, 186, 0.24);
}

.mainNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 2rem;
  background: #ffffff !important;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.navbarHome {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  /* margin-bottom: 2rem; */
  color: white;
  background: #071718;
}

.navbarHome .navbar-light .navbar-toggler-icon {
  background-color: white !important;
}

.navbar-toggler {
  background-color: #fff !important;
}

/* .navbar img {
  /* height: 2rem; * /
  transform: scale(1.25);
} */

.navLink {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #181818;
}

.navLinkHome {
  color: white;
}

.navLinkHome:hover {
  color: #00d37f;
}

.navItemBtn {
  position: relative;
}

.navBtnWrapper {
  width: 170px;
  height: 50px;
}

.navBtn {
  font-weight: 600;
  font-size: 16px;
}

.navBtnText {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 2px ;
  color: #292e3c;
}

.navEllips {
  position: absolute;
  height: 50px;
  top: 3px;
  left: 0.3px;
}

.navbarHome .navEllips {
  position: absolute;
  height: 50px;
  top: 2px;
  left: 1.18px;
}

.navToggleHome {
  background-color: #fff !important;
}

@media screen and (max-width: 600px) {
  .navbar img {
    /* height: 2rem; */
    transform: scale(1);
  }
  .navbarHome {
    padding: 0.5rem 0.1rem;
  }

  .navbar {
    padding: 0.5rem 0.1rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .LoginBtnNav {
    margin-left: 0 !important;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem !important ;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .LoginBtnNav {
    margin-left: 0 !important;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem !important ;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .LoginBtnNav {
    margin-left: 0 !important;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem !important ;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .LoginBtnNav {
    margin-left: 0 !important;
  }
}

@media all and (max-width: 800px) and (min-width: 800px) and (min-height: 1280px) and (max-height: 1280px) {
  .LoginBtnNav {
    margin-left: 0 !important;
  }
}

@media only screen and (device-height: 414px) and (device-width: 896px) and (-webkit-device-pixel-ratio: 2) {
  .LoginBtnNav {
    margin-left: 0 !important;
  }
}

@media only screen and (device-height: 390px) and (device-width: 844px) and (-webkit-device-pixel-ratio: 3) {
  .LoginBtnNav {
    margin-left: 0 !important;
  }
}

@media only screen and (device-height: 390px) and (device-width: 844px) and (-webkit-device-pixel-ratio: 3) {
  .LoginBtnNav {
    margin-left: 0 !important;
  }
}

@media only screen and (device-height: 1180px) and (device-width: 820px) and (-webkit-device-pixel-ratio: 2) {
  .LoginBtnNav {
    margin-left: 0 !important;
  }
}
