.differentWays {
  text-align: left;
  padding-top: 156px
  /* padding: 3rem 0 3rem; */
}

.differentWays .ways{
  font-family: 'Manrope';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 160%;
padding-top: 75px;
letter-spacing: 0.01em;

color: #FFFFFF;
}

.differentWays .subHeading {
  color: #00D37F;
  position: relative;
}

/* .differentWays .subHeading::after {
  content: '';
  position: absolute;
  right: 0;
  width: 191px;
  bottom: 0;
  height: 17px;
  background-image: url('../../assets/home/headingLine.svg');
} */

.bgTitleLine{
  position: relative;
}

.bgTitleLine::after {
  content: '';
  position: absolute;
  right: 0;
  width: 150px;
  bottom: -17px;
  height: 17px;
  background-image: url('../../assets/home/headingLine.svg');
  background-repeat: no-repeat;
}

.differentWays .investTitle{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 160%;
  /* identical to box height, or 48px */
  padding-top: 40px;
  text-align: center;
  
  color: #00D37F;
}

.differentWays .investCard {
  background-color: transparent;
  padding-top: 20px;
  text-align: center;
}

.differentWays .investContent{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  /* or 32px */
  padding-top: 20px;
  text-align: center;
  min-height: 180px;
  color: #FFFFFF;
}

.differentWays .investBest{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  /* or 32px */
  padding-top: 52px;
  text-align: center;
  
  color: #CEFD1E;
}


.differentWays h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.differentWays p {
  font-family: 'Manrope';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 140%;
/* or 45px */

letter-spacing: 0.01em;

color: #FFFFFF;
}

.investCardImgs{
  
  background-size: contain;
  background-repeat: no-repeat;
}

/* .differentWays .investCardImgs::after {
  content:"- Remember this" ;
  width:100%;
  position: absolute;
  background-image: url('../../assets/home/simulateBg.svg');
} */

/* .differentWays .flex {
  display: flex;
  gap: 2rem;
}

.differentWays .flex > div {
  width: 100%;
} */

@media (min-width: 320px) and (max-width: 480px) {
  .differentWays {
    padding-top: 20px;
    margin-top: 20px;
    /* padding: 3rem 0 3rem; */
  }

  .differentWays h2 {
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
  }

  .differentWays .subHeading {
    font-size: 32px;
    text-align: center;
  }

  .differentWays p {
    font-size: 20px;
  }

  .differentWays .ways {
    text-align: center;
    font-size: 26px;
    padding-top: 20px;
    line-height: 120%;
  }

  .differentWays p {
    font-size: 26px;
    text-align: center;
  }

  .bgTitleLine::after {
    width: 112px;    
  }
  
  
}

@media (min-width: 481px) and (max-width: 767px) {
  .differentWays {
    padding-top: 74px;
  }
  .invest_container .mainImg {
    padding-top: 20px;
  }
  .invest_container {
    padding-top: 0;
  }
  .invest_container .inner_container p {
    font-size: 1rem;
  }
  .differentWays h2 {
    font-size: 32px;
    margin-bottom: 45px;
    text-align: center;
  }
  .differentWays .subHeading {
    font-size: 32px;
    text-align: center;
  }
  .invest_container .inner_container h1 {
    font-size: 3.6em;
  }
  .invest_container .inner_container h5 {
    font-size: 0.8rem;
  }
  .differentWays .investContent{
    min-height: 350px;
  }
  .differentWays .ways {
    font-size: 26px;
    padding-top: 20px;
    line-height: 120%;
    text-align: center;
  }
  .differentWays p {
    font-size: 26px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .differentWays {
    padding-top: 70px
    /* padding: 3rem 0 3rem; */
  }

  .investCardImgs {
    width: 100%;
    min-height: 200px;
  }

  .differentWays .investContent{
    font-size: 20px;
    line-height: 160%; 
  }
  .differentWays .investContent{
    min-height: 350px;
  }

  .differentWays h2 {
    font-size: 32px;
    margin-bottom: 45px;
    text-align: center;
  }

  .differentWays p {
    font-size: 26px;
    text-align: center;
  }

  .differentWays .ways {
    padding-top: 5px;
    font-size: 26px;
    text-align: center;
  }

}

