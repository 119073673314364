.cm_btn{
    margin-left: 5px;
    min-width: 67px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 11px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
}
.cm_btn.start_btn{
    background: rgba(0, 211, 127, 0.21);
}
.cm_btn.paused_btn{
    background: rgba(252, 176, 23, 0.21);
}
.cm_btn.start_btn:hover{
    background: rgba(0, 211, 127, 0.41);
}
.cm_btn.paused_btn:hover{
    background: rgba(252, 176, 23, 0.41);
}
.cm_btn.exit_btn{
    background: rgba(197, 40, 61, 0.21);
}
.cm_btn.exit_btn:hover{
    background: rgba(197, 40, 61, 0.41);
}
.rount_ntn{
    min-width: 12px;
    height: 12px;
    background-color: transparent;
    display: flex;
    align-items: center;
    border-radius: 100%;
    margin: 0px;
    padding: 0px;
    outline: none;
    margin: 0 6px;
}
.cm_btn{
    opacity: 0;
}
.rount_ntn:hover + .cm_btn{
    opacity: 1;
}
.rount_ntn.entry_dot{
    background-color: #00D37F;
}
.rount_ntn.exit_dot{
    background-color: #C5283D;
}
.bs_btn{
    margin-left: 5px;
    max-width: 67px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 11px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
}
.f_green_color.bs_btn{
    background: rgba(0, 211, 127, 0.41);
}
.f_red_color.bs_btn{
    background: rgba(197, 40, 61, 0.41);
}