.pricing_card {
  margin: 20px auto;
  width: 95%;
  padding: 30px;
  background: linear-gradient(122.75deg, #191f20 -43.98%, #204348 100%);
  border: 0.5px solid #1d1d1d;
  box-sizing: border-box;
  border-radius: 12px;
}

.top_secn {
  display: flex;
  justify-content: space-between;
}

.pricing_btn {
  width: 135px;
  height: 40px;

  /* background: #cefd1e; */
  border-radius: 22.5px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: #191919;
  text-align: center;
}

.price {
  display: flex;
  justify-content: end;
  align-items: baseline;
}

.amount {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  /* line-height: 46px; */

  color: #ffffff;
}

.month {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 22px; */

  color: #ffffff;
}

.desc {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: #ffffff;

  text-align: start;
  margin-top: 10px;
}

.section {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.sub_section {
  display: flex;
}

.icon {
  width: 30px;
  height: 30px;
  background: #00d37f;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.icon + img {
  width: 20px;
  height: 20px;
}
.title {
  margin-left: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.value {
  margin-left: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;

  color: #ffffff;
}

.subscribe {
  border: 1px solid #00d37f;
  box-sizing: border-box;
  border-radius: 6px;

  padding: 20px 30px;
  margin-left: 50px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #00d37f;
}

.subscribe:hover {
  background: #00d37f;
  cursor: pointer;
  color: #ffffff;
}

.subscribe .btn{
  outline: none;
  border: none;
  background-color: transparent;
}
