:root {
  --hkgmed: 'HKG-med';
}

.invest_container {
  text-align: center;
  margin-top: 50px;
  position: relative;
  z-index: 1;
  max-width: 100vw;
}

.invest_container .investText {
  margin-top: 0px;
 
}

.invest_container h1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 87.42px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.invest_container span {
  letter-spacing: 0.01em;
  color: #00d37f;
}

.invest_container p {
  margin-top: 35px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}

.imgWrapper {
  position: relative;
}

.invest_container .btnImg {
  position: absolute;
  height: 60px;
}

.invest_container .homeBtnWrapper {
  width: 8rem;
  height: 55px;
}

.invest_container .homeBtn {
  font-weight: bold;
  font-size: 18px;
  padding-left: 17px;
}

.subHeadingText p {
  font-size: 24px;
  font-weight: 800;
  line-height: 32.78px;
}
.leftGreenishCorner {
  position: absolute;
  z-index: 0;
  width: 647px;
  height: 647px;
  left: -208px;
  top: 467px;
  background: rgba(209, 250, 55, 0.06);
  filter: blur(40px);
}

.mainImg {
  overflow: hidden;
}

.supersub {
  font-size: 16px;
  margin-top: 30px;
  color:white;
}

/* .invest_container h5 {
    font-style: normal;
    font-weight: bold;
    letter-spacing: -0.005em;
    color: #8c8c8c;
    font-size: 14px;
    opacity: 0;
  } */

/* .invest_container h5 span {
    color: #5c45cc;
  } */

/* .same_section {
    background: linear-gradient(177.77deg, #191919 22.48%, #062022 73.66%);
  } */

  .footer {
    /* padding: 3rem 0; */
    padding-top: 3rem;
    padding-bottom: 1rem;
    background-color: #082427;
    width: 100%;
    color: white;
    font-family: 'Manrope', sans-serif;
  
    background-size: contain;
    background-repeat: no-repeat;
    outline: none;
    border: 0;
  }


  .social_handles {
    display: flex;
    justify-content: center
    
    ;
    /* gap: 2rem; */
    font-size: 1rem;
    padding-top: 31px;
  }
  
  .social_handles a {
    background-color: white;
    border-radius: 50%;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: space-around;
    margin-top: 5px;
    width: 46.5px;
    margin-left: 10px;
  }
  
  .social_handles a:hover {
    background: #00d37f !important;
  }


  .social_handles a img {
    width: 50%;
  }
  
  .social_handles a:first-child {
    margin-left: 0;
  }

@media (min-width: 320px) and (max-width: 480px) {
  .invest_container .mainImg {
    padding-top: 50px;
  }

  .invest_container {
    margin-top: 50px;
  }
  .invest_container p {
    font-size: 20px;
  }
  .invest_container h1 {
    font-size: 30px;
    line-height: 50px;
  }
  .leftGreenishCorner {
    left: 0;
    top: 100%;
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .invest_container .mainImg {
    padding-top: 20px;
  }
  .invest_container {
    margin-top: 50px;
  }
  .invest_container p {
    font-size: 1rem;
  }
  .invest_container h1 {
    font-size: 2.6em;
  }
  .invest_container h5 {
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .invest_container .mainImg {
    padding-top: 50px;
  }
  .invest_container {
    margin-top: 50px;
  }

  .invest_container p {
    font-size: 1rem;
  }
  .invest_container h1 {
    font-size: 2.9rem;
  }
}

@media only screen and (max-width: 1200px) {
  /* .avg-review-top {
        right: 30%;
    } */
}

/* @media screen and (max-width: 1350px) {
    .invest_container p {
      font-size: 1rem;
    }
    .invest_container h1 {
      font-size: 4rem;
    }
  } */

/* 
  @media screen and (max-width: 380px) {
  
  
    .invest_container p {
      font-size: 0.8rem;
      padding: 0.4rem;
    }
    .invest_container h1 {
      font-size: 2.8rem;
    }
  } */
/* 
  @keyframes slidein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  } */


@media screen and (min-width: 576px) {
  .rightFluid {
    width: calc(((100% - 550px) / 2) + 550px) !important;
    margin-right: 0;
    margin-left: auto;
  }

}

@media screen and (min-width: 650px) {
  .rightFluid {
    width: calc(((100% - 620px) / 2) + 620px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) {
  .rightFluid {
    width: calc(((100% - 720px) / 2) + 720px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 850px) {
  .rightFluid {
    width: calc(((100% - 820px) / 2) + 820px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 930px) {
  .rightFluid {
    width: calc(((100% - 900px) / 2) + 900px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 992px) {
  .rightFluid {
    width: calc(((100% - 960px) / 2) + 960px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .rightFluid {
    width: calc(((100% - 1140px) / 2) + 1140px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 1400px) {
  .rightFluid {
    width: calc(((100% - 1320px) / 2) + 1320px) !important;
    margin-right: 0;
    margin-left: auto;
  }
 
}

@media screen and (max-width:1000px){
  .leftLayout {
    display: none;
  }
  .rightLayout {
    display: none;
  }
  .social_handles{
    margin-bottom: 100px;
  }
  .invest_container .investText {
    margin-top: 0px;
   
  }
  
}
