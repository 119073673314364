.description {
  background-color: #191919;
  padding: 0 30px 1.5rem;
  color: white;
  text-align: left;
}

.description .flexdetails {
  display: flex;
  align-items: start;
  gap: 1.5rem;
  font-size: 1rem;
}

.description .flexdetails>div {
  width: 100%;
}

.description .details {
  overflow: hidden;
  background-color: #1d1d1d !important;
  display: flex;
  padding: 30px;
  margin: auto -30px;
  margin-bottom: 1.2rem;
  align-items: center;
  position: relative;
  z-index: 1;
}

.description .details .quantInfo {
  display: flex;
}


.description .details .quantInfo>div:last-child {
  width: 50%;
}

.description .deploy {
  display: flex;
  gap: 2.5%;
}

.description .deploy>div:first-child {
  width: 70%;
  margin: 0.8rem 0;
}

.description .histogram .deploy>button {
  width: 27.5%;
  background-color: #00d37f;
}

.description .details p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.description .details .svg {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.description .category {
  color: #FCB017;
}

.description .details button.subscribe {
  padding: 0.7rem 1.1rem;
  color: black;
  font-size: 1.15rem;
  box-sizing: border-box;
  margin-top: 0.7rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  background-color: #cefd1e;
  font-weight: 500;
}

.description .details button.subscribed {
  padding: 0.7rem 1.3rem;
  color: black;
  font-size: 1.15rem;
  box-sizing: border-box;
  margin-top: 0.7rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: 1px solid #cefd1e;
  background-color: transparent;
  font-weight: 500;
  color: #cefd1e;
}

.description .details button:hover {
  transform: scale(1.05);
}

.description .details .svg img {
  width: 30px;
  height: auto;
  object-fit: none;
  margin-right: 0.5rem;
  background-color: #cefd1e;
}

.description .details h6 {
  margin: 0.7rem 0rem;
  font-size: 15px;
  line-height: 1.4;
}

.description .details h2,
.description .details h3 {
  margin: 0.8rem 0;
}

.description .details h2 {
  font-weight: 700;
  margin: 6px 0 10px;
}

.description .details h3 {
  color: #00d37f;
  font-weight: 600;
}

.description_img_wrap {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.description .details img {
  width: 250px;
  height: 250px;
  object-fit: contain;
  border-radius: 0.5rem;
}

.description .details>div:first-child {
  position: relative;
}

.description .details .difficulty {
  width: auto;
  color: white;
  position: absolute;
  left: 0;
  top: 1rem;
  font-size: 1rem;
  padding: 0 0.5rem;
}

.description .details>div:last-child {
  flex-grow: 1;
  margin-left: 1.5rem;
}

.description .histogram {
  display: flex;
  align-items: stretch;
  margin-bottom: 2rem;
  margin-top: 1.2rem;
  gap: 2rem;
}

.description .histogram>div {
  width: 100%;
}

.description .histogram>div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.description .histogram img {
  object-fit: cover;
}

.description .histogram h6 {
  color: #bfc0c0;
  margin-bottom: 0.3rem;
  font-size: 0.8rem;
  margin-top: 0.3rem;
}

.description .histogram p {
  font-size: 1rem;
  word-wrap: break-word;
  line-height: 1.8;
}

.description .histogram button {
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  border-radius: 0.4rem;
  padding: 0.777rem 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 0.7rem;
  transition: all 0.3s ease-in-out;
}

.description .histogram button:hover {
  color: white;
  background-color: #00d37f;
  transform: scale(1.05);

}

.description .flex {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.description .flex>div {
  width: 100%;
}

.description .flex>div h6 {
  margin-bottom: 1rem;
}

.description .flex>div div {
  width: 100%;
}

.description .flex>div div fieldset,
.description .deploy fieldset {
  top: -2px;
}

.quantbackground_img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  height: 100%;
  background-color: #1d1d1d;
  z-index: -1;
}

.details .quantbackground_img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  mix-blend-mode: overlay;
}

@media (max-width:1400px) {
  .description .flexdetails {
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }

  .description .flexdetails>div {
    width: calc(50% - 0.5rem);
    flex-shrink: 0;
  }

  .description .flexdetails>div p {
    margin-bottom: 0;
  }
}

@media (max-width:900px) {
  .description .histogram {
    flex-direction: column;
  }

  .description .details {
    background-image: none !important;
  }
}

@media (max-width:800px) {
  .description .details {
    flex-direction: column;
    align-items: center;
  }

  .description .details img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  .description .details>div {
    width: 100%;
  }

}

@media (max-width:600px) {

  .description .details img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  .description .details .svg_container>div {
    width: 100%;
  }

  .description .histogram h6 {
    font-size: 0.85rem;
  }

  .description .histogram p {
    font-size: 1rem;
  }
}


@media screen and (max-width:1250px) {
  .description .details .quantInfo>div:first-child {
    width: 60%;
  }

  .description .details .quantInfo>div:last-child {
    width: 40%;
  }
}

@media screen and (max-width:750px) {
  .description .details {
    padding: 22px;
    margin: auto -22px;
  }

  .description {
    padding: 0 22px 1rem;
  }

  .description_wrap {
    max-width: 300px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width:600px) {
  .description .histogram button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}

@media screen and (max-width:580px) {
  .description .details .quantInfo {
    flex-direction: column;
    align-items: center;
  }

  .description .details .svg {
    display: flex;
    justify-content: center;
  }

  .description .details .quantInfo>div:first-child {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .description .details .quantInfo>div:last-child {
    width: 60%;
  }

  .description .details>div:last-child {
    text-align: center;
  }
}