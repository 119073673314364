.right_secn {
  width: 100%;
  /* margin-left: 30px; */
  padding-bottom: 40px;
  background: #1d1d1d;
  height: 100vh;
  overflow-y: auto;
}

.title {
  padding: 10px;
  padding-left: 25px;
  text-align: start;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;

  color: #ffffff;
}
.planradio {
  width: 124px;
  height: 42px;
  border-radius: 4px;
  color: white;

  margin: 20px 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* background: #00d37f; */
  display: flex;
  align-items: center;
  justify-content: center;

  /* background: #242424;
  border: 1px solid #5a5a5a;
  border-radius: 4px; */
}
input[type='radio'] {
  margin-right: 10px;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #5a5a5a;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.plantype {
  display: flex;
  margin-left: 20px;
}

.card {
  display: flex;
}

.subsub {
  display: flex;
}

.title_perf {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  margin-left: 80px;
  margin-top: 20px;
  text-align: start;
  color: #ffffff;
}

.perfom {
  background: linear-gradient(254.54deg, #3e51a3 -11.09%, #00d37f 148.9%);
  border-radius: 6px;
  display: flex;
  padding: 50px;
  margin: 10px 70px;
  justify-content: space-evenly;
}

.value {
  margin-left: 10px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;

  color: #ffffff;
}

.titlerec {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-left: 10px;
}

.icon {
  width: 30px;
  height: 30px;
  background: #00d37f;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.icon + img {
  width: 20px;
  height: 20px;
}

.sub_section {
  display: flex;
}
