.imre_container {
  background: transparent;
}

.imre_container h2 {
  letter-spacing: -0.015em;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: #e7edee;
  text-align: left;
  padding: 1.8rem 0;
  font-size: 3rem;
}

.imre_container p {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
  font-feature-settings: 'salt' on;
  color: #e7edee;
  text-align: left;
  padding-right: 4rem;
  margin-bottom: 2rem;
}

.imre_container button {
  background: #cefd1e;
  box-shadow: 0px 22px 63px rgba(210, 210, 210, 0.03);
  border-radius: 7px;
  padding: 16px;
  outline: none;
  width: 184px;
  height: 64px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 132%;
}

.imre_container .flex {
  display: flex;
}

.imre_container .flex > div {
  width: 100%;
}

.imre_container .flex .tex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 2rem;
}

.imre_container .flex .img {
  margin: 10px 0 10px 0;
}

.imre_container .flex .img img {
  min-height: 350px;
  object-fit: cover;
}

@media screen and (max-width: 1350px) {
  .img img {
    width: 100%;
    height: auto;
  }
  .imre_container .flex {
    padding: 3rem 0;
  }
  .imre_container h2 {
    font-size: 48px;
  }
  .imre_container p {
    font-size: 20px;
    text-align: left;
  }
}

@media screen and (max-width: 850px) {
  .imre_container .flex {
    padding: 10px;
  }
  .imre_container .flex {
    padding: 4rem 0;
  }
  .imre_container h2 {
    font-size: 36px;
  }
  .imre_container p {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .imre_container .flex {
    flex-direction: column-reverse;
  }
  .imre_container .flex .tex {
    align-items: center;
    padding-left: 0;
  }
  .imre_container p {
    text-align: center;
    font-size: 1.3rem;
    padding-right: 0;
  }
  .imre_container h2 {
    text-align: center;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 500px) {
  .imre_container button {
    width: 11.148rem;
    height: 3rem;
    font-size: 0.875rem;
  }
}
