.dashboard_container {
  color: white;
  background-color: #191919;
  padding: 1.5rem;
}

.dashboard_container h4 {
  font-weight: 700;
  font-size: 1.75rem;
  text-align: left;
}

/* have to change the height to 166px with desired changes in font size in each indiv card */

.card_section {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  gap: 1rem;
}

.inner_container {
  padding: 0.4rem;
}

.right_section {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
}

.noDataTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #00d37f;
  margin-top: 41.63;
}

.noDataContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding-top: 5px;
}

.selectValue {
  margin-top: 29px;
}

.portfolioValue {
  background: #1d1d1d;
  border-radius: 6px;
  padding: 23px 20px;
}

.portfolioValue .pStaticValue {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  color: #bfc0c0;
}

.portfolioValue .pValue {
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  padding-left: 3px;
  padding-top: 3px;
  color: #ffffff;
}

.portfolioValue input[type='checkbox'] {
  margin-top: 3px;
  width: 35px;
  height: 35px;
}

/* /* .portfolioValue input[type="checkbox"]{
  background: none; -webkit-appearance: none;
  border: 1px solid #363636;
border-radius: 2px;
margin-top: 3px;
width: 40px;
height: 40px;
} */

/* 
.portfolioValue input[type=checkbox]:checked {
  background: #278396;
  border-radius: 2px;
}

.portfolioValue input[type=checkbox]:checked::before {
  box-shadow: none;
  background-image: url("../../assets/checkboxRight.svg");
}  */

.nextValue {
  width: 120px;
  height: 40px;
  background: #00d37f;
  border-radius: 6px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-left: 19px;
  margin-top: 41px;
}

.dashboardProgress {
  height: 4px;
  color: #00d37f;
}

.backValue {
  width: 120px;
  height: 40px;
  background: #191919;
  border: 1px solid #00d37f;
  border-radius: 6px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 41px;
}

.DashboardFlyImg{
  margin-top: 112px;
}

@media screen and (max-width: 800px) {
  .card_section {
    display: block;
  }
  .right_section {
    width: 100%;
  }
  .card_section > div {
    padding: 1rem;
    margin: 1rem;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .dashboard_container .db_header {
    text-align: center;
  }
}

@media screen and (max-width: 1300px) {
  .right_section {
    display: block;
  }
  .right_section > div {
    padding: 1rem;
  }
}
