.db_container {
  width: 350px;
}

.db_title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
}

.db_title h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}

.db_subcontent1 {
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 20px; */
}

.db_subcontent {
  background: #1d1d1d;
  border-radius: 6px;
  padding: 0.3rem 1rem;
  margin-bottom: 20px;
}
/* .db_subcontent_active {
  background: #191919;
  border-radius: 6px;
  padding: 0.3rem 1rem;
  margin-bottom: 20px;
} */
.db_tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: solid 1px white; */
}

.db_tile > img {
  margin: 20px;
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
}

.db_tile > img:hover {
  cursor: pointer;
  opacity: 0.8;
}

.sub_text {
  width: 70%;
  font-family: HKGrotesk;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

._text {
  padding-left: 2rem;
}

.initial {
  display: flex;
  justify-content: space-between;
}

a {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #bfc0c0;
  text-decoration: none;
  padding: 0.4rem;
}

li {
  text-align: left;
  list-style: none;
}

.notifications {
  padding-top: 2rem;
}

@font-face {
  font-family: HKGrotesk;
  src: url(../../../assets/fonts/HKGrotesk-Regular.ttf);
}

@media screen and (max-width: 1300px) {
  .notifications .initial h4 {
    font-size: 20px;
  }
  .notifications .initial {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 760px) {
  .db_container {
    width: 100%;
    padding: 1rem;
  }
}

/* @media screen and (max-width: 420px) {
  .db_container {
    width: 300px;
    padding: 2rem;
  }
}

@media screen and (max-width: 360px) {
  .db_container {
    width: 280px;
    padding: 1rem;
  }
} */
