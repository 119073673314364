.adm_body {
  background: rgba(25, 25, 25, 1);
  color: #fff;
  /* height: 100vh; */
  padding-bottom: 100px;
}

.adm_head {
  padding: 50px 0px;
  display: flex;
  width: 85vw;
  margin: auto;
}

.adm_head > div {
  margin-right: 20px;
}

.head_btn_selected {
  width: 175px;
  height: 40px;
  line-height: 40px;
  background: #00d37f;
  border: 1px solid #00d37f;
  box-sizing: border-box;
  border-radius: 6px;
}

.head_btn {
  width: 175px;
  height: 40px;
  line-height: 40px;
  background: #191919;
  border: 1px solid #00d37f;
  box-sizing: border-box;
  border-radius: 6px;
}

.adm_cards {
  display: flex;
  justify-content: space-between;
  width: 85vw;
  margin: auto;
}

.adm_card {
  width: 200px;
  height: 159px;

  background: rgba(51, 51, 51, 0.5);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.adm_card_title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.adm_card_val {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;

  color: #00d37f;
}

.adm_sub_hd {
  display: flex;
  justify-content: space-between;
  width: 85vw;
  margin: auto;
  padding: 30px 0px;
  /* flex-direction: row; */
}

.adm_sub_hd_menu > div {
  min-width: 310px;
  width: 20vw;
  max-width: 700px;
  /* margin: 5px; */
}

.adm_sub_hd_title {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;

  color: #ffffff;
}

.adm_sub_bd {
  width: 85vw;
  margin: auto;
  height: 600px;
  position: relative;
  background: #1d1d1d;
  border-radius: 6px;
}

.adm_sub_dpdwn {
  display: flex;
  justify-content: space-around;
}

.adm_sub_dp_item > div {
  min-width: 310px;
  width: 20vw;
  max-width: 700px;
  text-align: start;
  height: 55px;
  line-height: 55px;
}

.updateStatus {
  width: 250px;
  height: 55px;
  line-height: 55px;
  background: #00d37f;
  border-radius: 6px;
}

.adm_sub_dpdwn {
  padding: 60px 20px;

  border-bottom: 1px solid rgba(191, 192, 192, 0.2);
}

.adm_bd_foot {
  position: absolute;
  bottom: 15px;
  left: 40px;
  margin-right: 40px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.9;
  text-align: start;
}
