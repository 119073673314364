.popularSuggestionBtn {
  background: rgba(16, 79, 85, 0.05);
  border-radius: 15px;
  width: 105px;
  height: 30px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #104f55;
}

.popularBlogCard {
  margin: 118px 0;
}

@media (min-width: 320px) and (max-width: 480px) {
}
