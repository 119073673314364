.carousel {
  width: 100%;
  z-index: 100;
  margin: 20px 0;
  position: relative;
}

.carousel:hover {
  z-index: 200;
}

.carousel_heading {
  display: flex;
  color: white;
}

.carousel_heading a {
  font-size: 1rem;
}

.carousel_heading h4{
  font-size: 1.125rem;
  margin-bottom: 0px;
}
.carousel_heading a{
  padding: 0px;
}
.carousel_heading a,
.carousel_heading a:hover,
.carousel_heading a:visited,
.carousel_heading a:focus,
.carousel_heading a:link {
  text-decoration: none;
  color: #bfc0c0;
  margin-left: auto;
  font-weight: 600;
  cursor: pointer;
}

.left,
.right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: white;
  z-index: 1000;
  cursor: pointer;
}

.left path,
.right path {
  pointer-events: none;
}

.left {
  left: -25px;
}

.right {
  right: -25px;
}

.carouselContainer {
  width: 100%;
}

.carouselContainer .visible {
  width: 100%;
  overflow-x: hidden;
}

.carouselContainer .visible > div {
  width: 100%;
}

.carouselContainer > div > div > div {
  display: flex;
  gap: 2%;
  width: 100%;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}

.carouselContainer > div > div > div > div {
  width: 22%;
  flex-shrink: 0;
}

@media screen and (max-width: 1500px) {
  .carouselContainer > div > div > div > div {
    width: 30%;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 900px) {
  .carouselContainer > div > div > div > div {
    width: 40%;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 750px) {
  .carouselContainer > div > div > div > div {
    width: 43%;
    flex-shrink: 0;
  }

  .carousel_heading a {
    font-size: 1rem;
  }

  .carousel_heading h4 {
    font-size: 1rem;
  }
  .left {
    left: -21px;
  }
  
  .right {
    right: -21px;
  }
}

@media screen and (max-width: 650px) {
  .carouselContainer > div > div > div > div {
    width: 47%;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 600px) {
  .carouselContainer > div > div > div > div {
    width: 60%;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 550px) {
  .carouselContainer > div > div > div > div {
    width: 67%;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 500px) {
  .carouselContainer > div > div > div > div {
    width: 85%;
    flex-shrink: 0;
  }
}
