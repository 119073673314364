.imgM {
  margin-top: 120px;
  text-align: center;
  border-style: none;
  min-height: 670px;
}

.imgM .subHeading {
  color: #00d37f;
}

.imgM img {
  max-width: 100%;
  height: auto;
  margin-top: 25px;
}

.imgM video {
  /* margin-top: 25px; */
}

.imgM h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 96px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

@media (min-width: 320px) and (max-width: 480px) {
  .imgM {
    margin: 50px 0;
   
    min-height: 210px;
  }
  .imgM .subHeading {
    color: #00d37f;
    font-size: 2.5rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .imgM {
    margin: 30px 0;
   
    min-height: 400px;
    
  }
 
}



@media (min-width: 768px) and (max-width: 1024px) {

  .invest_container .inner_container p {
    font-size: 1rem;
  }
  .invest_container .inner_container h1 {
    font-size: 4rem;
  }

  .imgM {
    margin: 70px 0;
    height: 520px;
  }
}

/* @keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
