:root {
    --hkgmed: 'HKG-med';
}

.proviso {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background: #191919;
    overflow: hidden;
    font-family: var(--hkgmed);
}

.pflex {
    opacity: 1;
    font-size: 3rem;
    color: #ffffff;
    line-height: 1;
    letter-spacing: -1.5%;
    margin-bottom: 4rem;
    text-align: center;
}

.proviso .pflex {
    z-index: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    padding: 1rem;
    margin: 2rem;
}

.proviso .pflex p {
    color: #8c8c8c;
    opacity: 1;
    font-size: 24px;
    margin-bottom: 3rem;
    letter-spacing: -0.5%;
}

.proviso .pflex h1 {
    font-size: 3.75rem;
}

.proviso .pflex h3 {
    font-size: 20px;
    background: linear-gradient(23.91deg, #d9f551 -23.43%, #00d37f 170.65%), linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings: 'salt' on;
}

.proviso .pflex button {
    outline: none;
    border: none;
    padding: 0.75em 1.75em;
    opacity: 1;
    font-size: 1.5rem;
    font-weight: bold;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.75);
    background: #00d37f;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 48px 0px;
    border-radius: 7px;
}

@media screen and (max-width: 600px) {
    .proviso .pflex h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 450px) {
    .proviso .pflex h1 {
        font-size: 1.5rem;
    }
    .proviso .pflex p {
        font-size: 1.2rem;
    }
}