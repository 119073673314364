.filter {
  height: 100vh;
  width: 320px;
  background-color: #242424;
  padding: 1.4rem;
  color: white;
  text-align: left;
  line-height: 1.5;
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(320px);
  overflow-y: scroll;
  z-index: 500;
  transition: all 0.5s ease-in-out;
}

.filter .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter .heading img {
  cursor: pointer;
  margin-right: 0.5rem;
}
.filter h3 {
  font-size: 1.3rem;
  margin: 0.3rem 0;
}

.filter .search {
  color: #bfc0c0;
  border-bottom: 1px solid #bfc0c0;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.filter .search img {
  cursor: pointer;
  width: 18px;
  margin-right: 0.5rem;
}
.filter h4 {
  font-size: 1.2rem;
  margin: 1.8rem 0 1rem;
}

.filter .input_button {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}

.filter input[type='radio'] {
  margin-right: 0.75rem;
  transform: scale(1.1);
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 1px solid #5a5a5a;
  border-radius: 50%;
  background-clip: content-box;
  padding: 2.5px;
  font-size: 1.1rem;
}

.filter input[type='checkbox'] {
  margin-right: 0.75rem;
  transform: scale(1.1);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 2px solid #5a5a5a;
  position: relative;
  padding: 2.5px;
  font-size: 1.1rem;
}

.filter input[type='checkbox']::after{
  content: "\2714";
  width: 17px;
  height: 13px;
  position: absolute;
  top:0;
  left: 0; 
  color:#00d37f;
  display:none;
}

.filter input[type='radio']:checked,
.filter input[type='checkbox']:checked {
  border: 2px solid #00d37f;
}

.filter input[type='radio']:checked{
  background-color: #00d37f;
}

.filter input[type='checkbox']:checked::after {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:-2px;
}

.filter input[type='text'] {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1rem;
  color: white;
  flex-grow: 1;
  font-size: 1.1rem;
}

.filter .tags button {
  padding: 0.4rem 0.7rem;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 5px;
  background-color: transparent;
  border: 1px #5a5a5a solid;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
}

.filter .tags button:hover {
  background-color: #00d37f;
}

.filter label {
  margin: 0;
}

.filter .button {
  display: flex;
  gap: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.filter .button button:first-child {
  padding: 0.1rem 1rem;
  color: #cefd1e;
  background-color: transparent;
  border: none;
  outline: none;
  border: 1px solid #cefd1e;
  border-radius: 5px;
}

.filter .button button:last-child {
  padding: 0.3rem 1.7rem;
  background-color: #cefd1e;
  color: black;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 1rem;
}

.filter .button button:hover {
  transform: scale(1.1);
}

.filter .translate {
  transform: translate-x(0);
}
