.right_aide_section{
    padding-left: 30px;
    width: calc(100% - 370px);
}
.my_payment_inner {
    display: flex;
    align-items: center;
    background-color: #1D1D1D;
    border: 0.5px solid #1D1D1D;
    border-radius: 12px;
    padding: 9px 20px;
    justify-content: space-between;
}
.my_payment_invoice_number_wrap {
    display: flex;
    align-items: center;
}
.my_payment_invoice_number_wrap + .my_payment_invoice_number_wrap{
    margin-left: 15px;
}
.cm_invoice_icon{
    width: 27px;
    height: 27px;
    border-radius: 100%;
    background: rgba(0, 211, 127, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cm_invoice_title_wrapper{
    margin-left: 13px;
}
.cm_invoice_title_wrapper h3 {
    font-size: 14px;
    color: #BFC0C0;
    height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}
.cm_invoice_title_wrapper p{
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;
}
.cm_download_icon{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cm_download_icon p{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #CEFD1E;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0pc;
    padding: 0px;
}
.cm_download_icon span{
    margin-top: 3px;
    color: #fff;
    font-size: 12px;
    display: inline-block;
}
.cm_download_icon:hover p{
    background-color: #fff;
}
.cm_download_icon:hover span{
    color: #fff;
}
.right_aide_section ul{
    padding-left: 0px;
}
.sidebarOpen .setting_wrap {
    transform: translateX(0px);
}
.my_payment ul li{
    margin-top: 10px;
}
.cm_table table{
    background-color: #1D1D1D;
}

.cm_table table th{
    color: #BFC0C0;
    white-space: nowrap;
}
.cm_table table td{
    color: #fff;
    white-space: nowrap;
}
.cm_title_arrow{
    display: flex;
    align-items: center;
}
.cm_title_arrow p{
    margin-bottom: 0px;
    margin-right: 10px;
}
.arrow > div{
    line-height: 1.15;
}
.person_image_and_text{
    display: flex;
    align-items: center;
}
.person_img{
    width: 43px;
    height: 43px;
    border-radius: 6px;
    background-color: #0F0E0E;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
}
.person_image_and_text span{
    font-size: 14px;
    color: #fff;
}
.cm_table table td.exit_color{
    color:#C5283D;
}
.cm_table table td.entry_color{
    color: #00D37F;
}
.cm_table table td{
    border: 0px;
}
.my_payment_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1D1D1D;
    border: 0.5px solid #1D1D1D;
    border-radius: 12px;
    padding: 9px 20px;
    color: #BFC0C0;
}
.my_payment_header p{
    margin-bottom: 0px;
}
.payment_table.cm_table table td{
    padding-top: 6px;
    padding-bottom: 6px;
}
.select_left_wrap .MuiFormControl-root{
    min-width: 240px;
}
.select_left_wrap .MuiFormControl-root fieldset{
    border-color:  rgba(51, 51, 51, 1);
}
.select_left_wrap .MuiInputLabel-root{
    color: #BFC0C0;
}
.MuiModal-root .MuiPaper-root{
    background-color: #242424;
    color: #fff;
}   
.select_left_wrap .MuiSvgIcon-root{
    display: none;
}
.MuiList-root .MuiMenuItem-root.Mui-selected {
    background-color: rgba(0, 186, 70, 0.08);
}
.MuiList-root .MuiMenuItem-root:hover{
    background-color: rgba(0, 186, 70, 0.08);
}
.select_left_wrap .MuiOutlinedInput-root.Mui-focused fieldset{
    border-color: #00D37F;
} 
.select_left_wrap .MuiInputLabel-root.Mui-focused{
    color: #BFC0C0;
}
.select_left_wrap .MuiOutlinedInput-root:hover fieldset{
    border-color: #00D37F;
}
.select_left_wrap .MuiSelect-select {
    color: #fff;
    text-align: left;
}
.select_left_wrap .MuiSelect-select{
    position: relative;
}
.select_left_wrap .MuiSelect-select::after{
    content: '';
    width: 14px;
    height: 7.5px;
    position: absolute;
    right: 15px;
    top: 50%;
    background-image: url('../../../assets/select_down_arrow.svg');
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateY(-50%) rotate(0deg);
}
.select_left_wrap .MuiSelect-select[aria-expanded="true"]::after{
    transform: translateY(-50%) rotate(180deg);
}
.my_report_select_wrap .select_left_wrap + .select_left_wrap{
    margin-left: 22px;
}
.my_report_select_wrap{
    display: flex;
    align-items: center;
}
.select_left_wrap p{
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    font-weight: 600;
}
.cm_pdf_btn{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00D37F;
    border: 1px solid #00D37F;
    border-radius: 6px;
    transition: all 0.4s ease-in-out;
}
.xls_pdf_btn_wrap{
    display: flex;
    align-items: center;
}
.xls_pdf_btn_wrap .cm_pdf_btn + .cm_pdf_btn{
    margin-left: 15px;
}
.my_report_elect_btn_wrap{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.cm_table table th{
    border-bottom: 1px solid #333333;
}
.my_report_table_wrap{
    margin-top: 20px;
}
.cm_pdf_btn:hover{
    background-color: transparent;
}

@media screen and (max-width: 1440px) {
    .right_aide_section {
        width: calc(100% - 300px);
    }
    .cm_invoice_title_wrapper {
        margin-left: 10px;
    }
    .cm_invoice_title_wrapper p{
        font-size: 16px;
    }
    .my_payment_invoice_number_wrap + .my_payment_invoice_number_wrap {
        margin-left: 10px;
    }
    .my_payment_inner{
        padding: 8px 12px;
    }
}

@media screen and (max-width: 1360px) {
    .my_payment_inner {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }   
    .my_payment_invoice_number_wrap{
        min-width: 32%;
    }
    .my_payment_invoice_number_wrap:nth-child(n + 4){
        margin-top: 10px;
    }
    .my_payment_header{
        display: none;
    }
    .my_payment ul li:first-child{
        margin-top: 0px;
    }
    .my_report_elect_btn_wrap{
        flex-direction: column;
    }
    .my_report_select_wrap{
        margin-bottom: 15px;
        width: 100%;
    }
    .select_left_wrap{
        flex-grow: 1;
        flex-basis: 50%;
    }
    .select_left_wrap .MuiFormControl-root {
        min-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 1199px) {
    .my_payment_inner {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .my_payment_invoice_number_wrap {
        min-width: 50%;
    }
    .my_payment_invoice_number_wrap + .my_payment_invoice_number_wrap {
        margin-left: 0px;
    }
    .my_payment_invoice_number_wrap:nth-child(n + 3) {
        margin-top: 10px;
    }
    .cm_download_icon p{
        width: 35px;
        height: 35px;
    }
}

@media screen and (max-width: 767px) {
    .right_aide_section {
        width: 100%;
        padding-left: 0px;
    }
    .my_payment_invoice_number_wrap {
        min-width: 33.33%;
    }
    .cm_invoice_title_wrapper p {
        font-size: 14px;
    }
    .cm_invoice_title_wrapper h3{
        margin-bottom: 0px;
    }
    .cm_download_icon p {
        width: 30px;
        height: 30px;
    }
}
@media screen and (max-width: 575px) {
    .my_payment_invoice_number_wrap {
        min-width: 50%;
    }
    .cm_pdf_btn {
        width: 45px;
        height: 45px;
    }
}

@media screen and (max-width: 367px) {
    .my_payment_invoice_number_wrap {
        width: 100%;
    }
    .my_payment_invoice_number_wrap:nth-child(n + 2) {
        margin-top: 10px;
    }
    .my_report_select_wrap{
        flex-direction: column;
        width: 100%;
    }
    .select_left_wrap{
        width: 100%;
    }
    .my_report_select_wrap .select_left_wrap + .select_left_wrap {
        margin-left: 0px;
        margin-top: 15px;
    }
}