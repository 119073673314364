:root {
  --hkgmed: 'HKG-med';
}

.invest_container {
  text-align: left;
  margin-top: 200px;
}

.invest_container .investText {
  margin-top: 78px;
}

.invest_container h1 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 96px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.invest_container span {
  letter-spacing: 0.01em;
  color: #00d37f;
}

.invest_container p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 160%;
  color: #ffffff;
}

.imgWrapper {
  position: relative;
}

.invest_container .btnImg {
  position: absolute;
  height: 60px;
}

.invest_container .homeBtnWrapper {
  width: 17.188rem;
  height: 60px;
}

.invest_container .homeBtn {
  font-weight: bold;
  font-size: 1.25rem;
  padding-left: 17px;
}

/* .invest_container h5 {
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.005em;
  color: #8c8c8c;
  font-size: 14px;
  opacity: 0;
} */

/* .invest_container h5 span {
  color: #5c45cc;
} */

/* .same_section {
  background: linear-gradient(177.77deg, #191919 22.48%, #062022 73.66%);
} */

@media (min-width: 320px) and (max-width: 480px) {
  .invest_container .mainImg {
    padding-top: 50px;
  }

  .invest_container {
    margin-top: 50px;
  }
  .invest_container p {
    font-size: 20px;
  }
  .invest_container h1 {
    font-size: 30px;
    line-height: 50px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .invest_container .mainImg {
    padding-top: 20px;
  }
  .invest_container {
    margin-top: 50px;
  }
  .invest_container p {
    font-size: 1rem;
  }
  .invest_container h1 {
    font-size: 2.6em;
  }
  .invest_container h5 {
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .invest_container .mainImg {
    padding-top: 50px;
  }
  .invest_container {
    margin-top: 50px;
  }

  .invest_container p {
    font-size: 1rem;
  }
  .invest_container h1 {
    font-size: 2.9rem;
  }
}

@media only screen and (max-width: 1200px) {
  /* .avg-review-top {
      right: 30%;
  } */
}

/* @media screen and (max-width: 1350px) {
  .invest_container p {
    font-size: 1rem;
  }
  .invest_container h1 {
    font-size: 4rem;
  }
} */

/* 
@media screen and (max-width: 380px) {


  .invest_container p {
    font-size: 0.8rem;
    padding: 0.4rem;
  }
  .invest_container h1 {
    font-size: 2.8rem;
  }
} */
/* 
@keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */



@media screen and (min-width: 576px) {
  .rightFluid {
    width: calc(((100% - 550px) / 2) + 550px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 650px) {
  .rightFluid {
    width: calc(((100% - 620px) / 2) + 620px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) {
  .rightFluid {
    width: calc(((100% - 720px) / 2) + 720px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 850px) {
  .rightFluid {
    width: calc(((100% - 820px) / 2) + 820px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 930px) {
  .rightFluid {
    width: calc(((100% - 900px) / 2) + 900px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 992px) {
  .rightFluid {
    width: calc(((100% - 960px) / 2) + 960px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .rightFluid {
    width: calc(((100% - 1140px) / 2) + 1140px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 1400px) {
  .rightFluid {
    width: calc(((100% - 1320px) / 2) + 1320px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}
