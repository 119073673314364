.subscribe_container {
  margin-top: -91px;
  margin-bottom: 50px;
  background: transparent;
  z-index: 2;
  position: relative;
}

.subscribe_container .subscribe_inner_container {
  background: linear-gradient(254.54deg, #69cad7 -11.09%, #5c45cc 148.9%);
  border-radius: 10px;
  padding: 32px 47px;
  text-align: center;
}

.subscribe_container .textSection {
  text-align: left;
}

.subscribe_container .textSection h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #ffffff;
  position: relative;
}

.subscribe_container .textSection p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  padding-top: 30px;
}

.subscribe_container .inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.subscribe_container .subscribeInput {
  background: #ffffff;
  border-radius: 8px;
  width: 342px;
  height: 60px;
  position: relative;
  border: none;
  color: black;
}

.subscribe_container .subscribeInput:focus{
  outline: none;
}

.subscribe_container .subscribeInput::placeholder {
  color: black;
  font-family: 'Manrope';
  font-style: normal;
  padding: 22px;
}

.subscribe_container .subscribeBtn {
  background: #00d37f;
  border-radius: 8px;
  width: 156px;
  height: 60px;
  position: absolute;
  right: 7px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

@media (min-width: 320px) and (max-width: 480px) {
  .subscribe_container .subscribeInput {
    width: 300px;
  }

  /* .subscribe_container .subscribeBtn {
    right: -13px;
  } */

  .subscribe_container .subscribe_inner_container{
    padding: 32px 10px;
  }

  .subscribe_container .subscribeBtn{
    right: 10px;
    width: 120px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .subscribe_container .subscribeInput {
    width: 300px;
  }

  .subscribe_container .subscribeBtn {
    right: -13px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .subscribe_container .subscribeBtn {
      right: -21px;
      width: 125px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      }
}


@media screen and (max-width: 1280px) {
  /* .subscribe_container .subscribeBtn {
    right: -21px;
    width: 125px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  } */
}