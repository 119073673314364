.right_secn {
  width: calc(100% - 370px);
  margin-left: 30px;
  padding-bottom: 40px;
  background: #1d1d1d;
  border-radius: 6px;
}

.editProfile {
  margin: 0px 60px;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

.inputs div {
  min-width: 310px;
  width: 20vw;
  max-width: 700px;
  /* margin: 5px; */
}

.avatar {
  width: 138px;
  height: 138px;
  clip-path: circle();
}

.top_secn {
  display: flex;
  margin: 30px 50px;
}

.profile {
  margin-left: 50px;
  margin-top: 50px;
  text-align: start;
}

.name {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.change {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #00d37f;
}

.change:hover {
  cursor: pointer;
}

.save {
  padding: 0.5rem 5rem;
  font-size: 1.2 rem;
  background-color: #00d37f;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  color: white;
  letter-spacing: 1;
}

.cancel {
  margin-right: 15px;
  padding: 0.5rem 5rem;
  font-size: 1.2 rem;
  background-color: #ee6461;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  color: white;
  letter-spacing: 1;
}

.buttons {
  margin-top: 30px;
  margin-right: 50px;
  text-align: end;
}

@media screen and (max-width: 900px) {
  .right_secn {
    margin-left: 0px;
    font-size: 1 rem;
  }

  .top_secn {
    flex-direction: column;
  }

  .name {
    align-items: start;
  }

  .profile {
    margin-left: 0px;
  }

  .buttons {
    margin: 0px;
    text-align: center;
  }

  .cancel {
    margin: 0px;
    margin-bottom: 20px;
  }

  .save {
    margin: 0px;
    /* width: 50vw; */
  }
}

@media screen and (max-width: 900px) {
  .right_secn {
    width: calc(100% - 300px);
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .right_secn {
    width: calc(100%);
    margin-left: 0px;
  }
}