.modalWidth{
    width: 80vw;
    max-width: none;
    border: none;
}

.modalWidth .header{
    background-color:#1D1D1D;
    color: white;
}

.modalWidth .header button{
    border-radius: 50%;
    border: 2px solid black;
    font-size: 0.6rem;
    filter: invert(100%) sepia(61%) saturate(6933%) hue-rotate(12deg) brightness(101%) contrast(97%);
}

.modalWidth .modalBody{
    background-color:#1D1D1D;
    color: white;
}

.list{
    padding: 1rem 0;
    background-color: #1D1D1D;
    text-align: center;
}

.list .listbuilder{
    padding: 0 1rem;
}

.list .listbuilder > div{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.list .listbuilder > div > *{
    width: 100%;
    display: flex;
    gap: 1rem;
}

.list .listbuilder > div > * > div{
    width: 100%;
}

.list .listbuilder > div > * > button{
    background-color: #00D37F;
    padding: 0.3rem 2.7rem;
    border-radius: 0.3rem;
    color: white;
    transition: all 0.3s ease-in-out;
}

.list .listbuilder > div > * > button:hover{
    transform: scale(1.1);
}

.list .table{
    margin: 2rem -1rem 1.2rem;
}
.list .table tr {
    display: flex;
}

.list .table tr > *{
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    padding: 0.5rem auto;
}

.list .save{
    background-color: #00D37F;
    padding: 0.6rem 10rem;
    font-size: 1.5rem;
    border-radius: 0.3rem;
    color: white;
    transition: all 0.3s ease-in-out;
}

.list .save:hover{
    transform: scale(1.03);
}