.siat_container {
  background: linear-gradient(
    360deg,
    #071718 2.9%,
    rgba(5, 18, 19, 0.46) 98.36%
  );
  text-align: left;
  position: relative;
  margin-top: 100px;
}

.siat_container .subHeading {
  color: #00d37f;
  position: relative;
}

/* .siat_container .subHeading::after {
  content: '';
  position: absolute;
  right: 0;
  width: 191px;
  bottom: 0;
  height: 17px;
  background-image: url('../../assets/home/headingLine.svg');
} */
/* .bgTitleLine::after {
  content: '';
  position: absolute;
  right: 0;
  width: 157px;
  bottom: -17px;
  height: 17px;
  background-image: url('../../assets/home/headingLine.svg');
} */

.siat_container_bg {
  height: 152px;
  margin-bottom: -67px;
  border-radius: 0% 0% 100% 100%;
  margin-top: 100px;
  background: #071718;
  position: relative;
}

.siat_container h2 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.siat_container p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding-top: 30px;
}

.siat_container .investImg {
  text-align: right;
}

.siat_container .investFactorDiv {
  padding-top: 50px;
}

.siat_container .investFactorCard {
  text-align: center;
  /* background: #104F55; */
  background: #082427;
  display: flex;
  min-height: 525px;
}

.siat_container .investFactorCard:hover {
  background: #104f55;
  color: #00d37f;
}

.siat_container .investFactorCard:hover .investTitle {
  color: #00d37f;
}

.siat_container .investImg {
  width: 150px;
  min-height: 174px;
}

.siat_container .investTitle {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.01em;
  /* color: #00d37f; */
  color: white;
  padding-top: 25px;
}

.siat_container .investContent {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  padding-top: 20px;
}

/* .siat_container .investFactorDiv img{
    padding-top: 50px;
} */

/* .siat_container .flex {
  display: flex;
}

.siat_container .flex > div {
  width: 100%;
}

.siat_container .flex .tex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 4rem;
}

.siat_container .flex .img {
  margin: 10px 0 10px 0;
}

.siat_container .flex .img img {
  min-height: 350px;
  object-fit: cover;
} */

@media (min-width: 320px) and (max-width: 480px) {
  .siat_container .investTitle {
    font-size: 22px;
  }
  .siat_container h2 {
    font-size: 32px;
    text-align: center;
  }
  .siat_container p {
    font-size: 20px;
  }
  /* .siat_container {
    margin-top: 250px !important; 
  } */

  .siat_container_bg {
    height: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: 0;
    position: relative;
  }
  .siat_container .investFactorCard {
    margin-top: 5px;
  }
  .siat_container .investFactorDiv {
    padding-top: 20px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .siat_container .investTitle {
    font-size: 22px;
  }
  .siat_container .investContent {
    font-size: 17px;
  }
  .siat_container .investImg {
    text-align: left;
  }

  .siat_container h2 {
    font-size: 32px;
    text-align: center;
  }

  .invest_container .inner_container p {
    font-size: 1rem;
  }
  .invest_container .inner_container h1 {
    font-size: 3.6em;
  }
  .invest_container .inner_container h5 {
    font-size: 0.8rem;
  }

  .siat_container_bg {
    height: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: 0;
    position: relative;
  }
  .siat_container .investFactorCard {
    margin-top: 5px;
  }
  .siat_container .investFactorDiv {
    padding-top: 20px;
  }
  .bgTitleLine::after {
    width: 100px;
  }
  .siat_container {
    margin-top: 150px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .siat_container {
    margin-top: 0;
  }

  .siat_container .investTitle {
    font-size: 22px;
  }
  .siat_container .investContent {
    font-size: 20px;
  }
  .invest_container .inner_container p {
    font-size: 20px;
  }
  .invest_container .inner_container h1 {
    font-size: 32px;
  }
  .siat_container_vector::after {
    content: '';
    height: 0;
    width: 0;
  }
  .siat_container_bg {
    height: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: 0;
    position: relative;
  }
  .siat_container .investFactorCard {
    margin-top: 5px;
  }
  .siat_container .investFactorDiv {
    padding: 30px;
  }
  .siat_container h2 {
    font-size: 2.9rem;
  }
}

@media screen and (max-width: 1280px) {
  .siat_container .investFactorCard {
    min-height: 525px;
  }
  .siat_container {
    margin-top: 150px;
  }
}

/* ======slider ======== */

.swiperActionBar {
  position: absolute;
  z-index: 2;
  right: 101%;
  top: calc(50% - 35px);
}

.swiperActionBar button {
  background: transparent;
  /* border: solid 3px #0b7762;
  color: #0b7762; */
  width: 30px;
  height: 30px;
  font-weight: 700;
  /* border-radius: 100%; */
  /* padding: 10px 12px; */
}

.swiperActionBar button:disabled {
  /* border: solid 3px #0C3D42;
  color: #0C3D42; */
}
@media screen and (max-width: 400px) {
  .swiperActionBar {
    position: absolute;
    z-index: 2;
    display: flex;
    gap: 1;
    right: 40%;
    top: calc(101%);
  }
}

@media screen and (min-width: 576px) {
  .siat_container .rightFluid {
    width: calc(((100% - 550px) / 2) + 550px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 650px) {
  .siat_container .rightFluid {
    width: calc(((100% - 620px) / 2) + 620px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 768px) {
  .siat_container .rightFluid {
    width: calc(((100% - 720px) / 2) + 720px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 850px) {
  .siat_container .rightFluid {
    width: calc(((100% - 820px) / 2) + 820px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 930px) {
  .siat_container .rightFluid {
    width: calc(((100% - 900px) / 2) + 900px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 992px) {
  .siat_container .rightFluid {
    width: calc(((100% - 960px) / 2) + 960px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .siat_container .rightFluid {
    width: calc(((100% - 1140px) / 2) + 1140px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 1400px) {
  .siat_container .rightFluid {
    width: calc(((100% - 1320px) / 2) + 1320px) !important;
    margin-right: 0;
    margin-left: auto;
  }
}
