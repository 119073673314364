:root {
  --hkgmed: 'HKG-med';
}
/* #051213 */
.affilatePage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  /* background: linear-gradient(90deg, #191919 -3.82%, #104f55 125.73%); */
  background: #051213;
  font-family: var(--hkgmed);
}

.homepage .flex {
  /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
  display: flex;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding: 2 rem;
}

.homepage .flex h1 {
  opacity: 1;
  font-size: 5rem;
  color: #ffffff;
  line-height: 1.2;
  letter-spacing: -1.5%;
  margin-bottom: 3rem;
  animation: slidein 1s ease-in-out forwards;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
}

.homepage .flex p {
  color: #e6fbf2;
  opacity: 0;
  font-size: 2.125rem;
  margin-bottom: 3rem;
  letter-spacing: -0.5%;
  animation: slidein 1s 0.5s ease-in-out forwards;
  font-family: HKG-med;
}

.homepage .flex p span {
  background: linear-gradient(23.91deg, #d9f551 -23.43%, #00d37f 170.65%),
    #ffffff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
}

/* .homepage button {
    background: linear-gradient(23.91deg, #d9f551 -23.43%, #00d37f 170.65%),
      #ffffff;
    outline: none;
    border: none;
    padding: 0.75em 1.75em;
    opacity: 0;
    font-size: 1.5rem;
    font-weight: bold;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.75);
    transition: all 0.2s ease-in-out;
    animation: slidein 1s 1s ease-in-out forwards;
    border-radius: 7px;
  } */

.homepage button:hover {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);
}

/* Annimations */

@keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* media queries(Responsiveness) */

@media screen and (max-width: 900px) {
  .homepage .flex h1 {
    font-size: 3.25rem;
    margin-bottom: 2rem;
  }
  .homepage .flex p {
    font-size: 1.95rem;
    margin-bottom: 2rem;
  }
  .homepage button {
    font-size: 0.975rem;
  }
}

@media screen and (max-width: 600px) {
  .homepage .flex h1 {
    font-size: 2.75rem;
    margin-bottom: 2rem;
  }
  .homepage .flex p {
    font-size: 1.65rem;
    margin-bottom: 2rem;
  }
  .homepage button {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 450px) {
  .homepage .flex h1 {
    font-size: 2.5rem;
  }
  .homepage .flex p {
    font-size: 1.4rem;
  }
  .homepage button {
    font-size: 0.9rem;
  }
}
