.Myquant {
    background-color: #191919;
    min-height: 100vh;
    width: 100%;
    height: auto;
    position: relative;
    padding: 1.5rem 1rem 1.5rem;
}

.Myquant .heading {
    display: flex;
    color: white;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .Myquant .heading button {
    margin-left: auto;
    padding: 0.6em 1.8em;
    background-color: #00d37f;
    color: white;
    border-radius: 0.5em;
    outline: none;
    font-size: 1rem;
    border: none;
    transition: all 0.3s;
  }
  
  .Myquant .heading button:hover {
    transform: scale(1.1);
  }
  
  .Myquant .heading button img {
    margin-right: 0.5rem;
  }