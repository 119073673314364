.com {
  /* height: 100%; */
  color: white;
  background-color: #191919;
  filter: blur(4px);
}
.com_soon {
  background-color: #191919;
}

.com_header {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;

  color: #ffffff;
  text-align: start;
  padding: 30px;
}

.com_body {
  display: flex;
}
.com_left {
  /* width: 100%; */
  height: 100%;
}

.soon {
  position: absolute;
  top: 50%;
  left: 50%;

  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  color: white;
  font-size: 50px;
  font-family: 'HK Grotesk';
  font-style: normal;
  font-weight: 700;
}
